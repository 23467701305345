import React from "react";
import "./profile-box.css"
import StatBox from "../GameStats/statbox";
import constants from "../../constants";
import GeneralButton from "../Common/general-button";
const { NO_IMG_PATH, BASE_IMG_PATH} = constants();

function SelfProfile(canEdit: boolean) {
  if (canEdit)
    return (<>
      <GeneralButton lbl="Edit Profile" dir="/edit"></GeneralButton>
    </>);
  else
    return;
}

type ProfileProps = {
  userData: UserObject;
  canEdit: boolean;
}

export default function ProfileBox({ userData, canEdit}: ProfileProps) {
  const replaceImage = (error : any) => {
    //replacement of broken Image
    error.target.src = NO_IMG_PATH
  }
  console.log(userData);
  let imgUrl : string = BASE_IMG_PATH + userData.username + ".png"
  if (userData.last_online === undefined) {
    userData.last_online = new Date(Date.now())
  }
  console.log(userData.games.lol)
  //Where the statbox is declared, place component that takes the userData.games as a prop and displays unique box for each linked game.
  return (
    <>
      <div id="profile-wrapper">
        <div id="prof-img-wrapper">
          <div id="prof-img-wrap">
          <img alt="temp_ User Avatar" id="prof-img-src" src={imgUrl} onError={replaceImage}/>
          </div>
          <br />
          {SelfProfile(canEdit)}
        </div>
        <div id="prof-info">
          <div className="prof-header" id="prof-header">Profile of {userData.username}</div>
          <div id="prof-cap-box">
          <div className="prof-caption">Bio:</div>
          <div className="prof-text">{userData.bio}</div>
          <div className="prof-caption">Language:</div>
          <div className="prof-text">{userData.language}</div>
          <div className="prof-caption" id="numtext">Last Online:</div>
          <div className="prof-text" id="numtext">{new Date(userData.last_online).toISOString().substring(0, 10)}</div>
          </div>
        </div>
        {(userData.games !== undefined) ? <StatBox gameObject={userData.games.lol} /> : <div></div>}
      </div>
    </>
  );
}