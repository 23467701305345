import React, { useState } from 'react';
import axios from "axios";
import "./Message.css"
import constants from "../../constants"
const {getNowAsUTC, SOURCE_IP} = constants()

type MessageSenderProps = {
  setToken: CallableFunction;
  token: string;
  sendTo: string;
  doLoading: CallableFunction | undefined;
  sentMsg?: CallableFunction;
}

export default function MessageSender({ setToken, token, sendTo, sentMsg, doLoading }: MessageSenderProps) {
  const [registerMsg, setMsg] = useState('')
  const [msgField, setMsgField] = useState('')
  const [sendBtnMsg, setBtnMsg] = useState('Send!');

  const sleep = (ms :  number) => new Promise(r => setTimeout(r, ms));

  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setMsgField(event.target.value);
  };

  const sendMessage = async (event: React.FormEvent<HTMLFormElement>) => {

    //const { token, removeToken, setToken } = useToken();
    event.preventDefault();
    console.log(event.target);
    const { message } = event.target as typeof event.target & {
      message: { value: string }
    }

    const dummyMsg: Msg = {
      message: message.value,
      sent: true,
      timestamp: new Date(getNowAsUTC())
    };
    if (sentMsg !== undefined) {
      sentMsg(dummyMsg)
    }

    axios({
      method: "POST",
      url: SOURCE_IP + "/send",
      headers: {
        Authorization: `Bearer ${token}`,
        receiver: sendTo,
        content: message.value,
      }
    }).then((response) => {
      if (response.data.access_token) {
        setToken(response.data.access_token)
        console.log("TOKEN FOUND")
      }

      if(doLoading !== undefined){
        doLoading()
      }

    }).catch((error) => {
      if (error.data && error.data.access_token) {
        setToken(error.data.access_token)
        console.log("TOKEN FOUND")
    }
      if (error.response) {
        setMsg(error.response.data.msg)
        console.log(error.response.data)
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
      }
    })

    setBtnMsg('Sent!');
    setMsgField('');
    await sleep(2000);
    setBtnMsg('Send!');

  }

  return (
    <div className="msg-sender">
      <form className="sender-form" onSubmit={sendMessage}>
        <div className="msg-sender-text-box">
            <input className="msg-sender-text" type="text" name="message" placeholder="Type your message here ..." value={msgField} onChange={handleChange}/>
        </div>

        <div className="msg-sender-submit">
          {/**<input type="submit" value="Update!" disabled={!(pass === "OK")} /> &nbsp;&nbsp;*/}
          <input className="msg-sender-submit-button" type="submit" value={sendBtnMsg}/>
        </div>
        <div>
          <label>
            {registerMsg}
          </label>
        </div>
      </form>
      {/**We need a way to set the token inside the COMPONENT
             * Currently it TRIES to set it in the imported ts file.
             * However it does not work and needs to be set inside the 
             * component with the passed function in props to prevent issues.
             * ((setToken is passed from App.tsx))
             */}
    </div>
  )
}