// File filled with types with predefined values to be used in the axios calls.
export default function constants() {
  // Should be matched to parity the games supported in the types.d.ts file
  const gs_def: GameStats = {
    lol: undefined,
    csgo: undefined,
  };

  const u_def: UserObject = {
    uid: 1,
    email: "",
    username: "",
    gender: "",
    img: "/queueup_img.png",
    bio: "",
    games: gs_def,
    discord: "",
    language: "",
    birth_date: new Date(Date.now()),
    isVerified: true,
    last_online: new Date(Date.now()),
  };

  const mu_def: MatchedUserObject = {
    img: undefined,
    birth_date: undefined,
    description: undefined,
    discord_name: undefined,
    language: undefined,
    last_login: undefined,
    lol: undefined,
    profile_name: undefined,
    register_date: undefined,
  };

  const ls_def: LeagueStats = {
    continent: "",
    tier: "",
    rank: "",
    rr: 0,
    level: 0,
    wins: 0,
    losses: 0,
    hot_streak: 0,
    preferred_role: "",
    flex_role: "",
    avg_kills: 0,
    avg_assists: 0,
    avg_deaths: 0,
    avg_gold: 0,
    avg_goldpm: 0,
    avg_visionscorepm: 0,
    avg_visionscore: 0,
    matches_pooled: 0,
    last_cached: 0,
  };

  const csgo_def: CSGOStats = {
    continent: "",
    tier: "",
    rank: "",
    rr: 0,
    wins: 0,
    losses: 0,
    hot_streak: 0,
    preferred_role: "",
    flex_role: "",
    avg_kills: 0,
    avg_assists: 0,
    avg_deaths: 0,
    avg_gold: 0,
    avg_goldpm: 0,
    avg_visionpm: 0,
    avg_visionscore: 0,
    matches_pooled: 0,
    last_cached: 0,
  };
  //=================Report Consts===============

  const TempReport: ReportObject = {
    reportee: "jeff2",
    content: "rude",
    timestamp: 0,
    type: "Meanie",
  };
  const TempUserReports: UserSpecificReports = {
    name: "Jeff",
    reports: [TempReport],
  };

  const TempList: ReportList = {
    list: [TempUserReports],
  };

  //USED FOR CHECKING BLOCKED USERS
  const bu_def: Msg = {
    message: "You blocked this user.",
    sent: false,
    timestamp: new Date(0),
  };

  //=================Enums============

  enum GamesParam {
    League = "lol",
    //Valorant = "val",
    //CSGO = "csgo",
  }
  enum LangParam {
    English = "english",
    Spanish = "spanish",
  }
  enum RoleParam {
    Top = "top",
    Middle = "middle",
    ADC = "bottom",
    Support = "utility",
    Jungle = "jungle",
  }

  //=====================

  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //loads the domain/IP from config.ts, making it easier to change from dev to prod
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN
  const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN

  const BASE_IMG_PATH = API_DOMAIN + "/static/pfps/"

  const ICON_PATH = BASE_DOMAIN + "/qupicon.png"
  const NO_IMG_PATH = BASE_DOMAIN + "/queueup_img.png"
  const LOL_FLAT_PATH = BASE_DOMAIN + "/lol_flatgold.png"
  const LOL_ICON_PATH = BASE_DOMAIN + "/lol_icon.png"

  function getNowAsUTC(){
    var date = new Date();
    return date
  }



  function getLocalAsUTC(localDate : Date){
    var date = localDate;
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                    date.getUTCDate(), date.getUTCHours(),
                    date.getUTCMinutes(), date.getUTCSeconds());
    return now_utc
  }

  function utcToPrettyDate(utc: number) {
    let bufDate = new Date(utc);
    return dateToPrettyDate(bufDate);
  }

  function stringToPrettyDate(str: string | undefined){
    if(str === undefined || str === "undefined"){
      return "NA"
    }
    let bufDate = new Date(str);
    return dateToPrettyDate(bufDate);
  }

  function pad(num : number, size : number) {
    let padNum = num.toString();
    while (padNum.length < size) padNum= "0" + num;
    return padNum;
}

  function dateToPrettyDate(ugly: Date | undefined) {

    if(ugly === undefined){
      return "NA"
    }

    let bufDate = new Date(ugly)
    const day = bufDate.getDate()
    const monthIndex = bufDate.getMonth()
    const year = bufDate.getFullYear()
    const minutes = pad(bufDate.getMinutes(), 2)
    const hours = pad(bufDate.getHours(), 2)
    const prettyDate =
      months[monthIndex] +
      "-" +
      day +
      "-" +
      year +
      " " +
      hours +
      ":" +
      minutes;
    
    return prettyDate;
  }

  //KDA Ratio = (K+A) / Max(1,D)
  function CalcKDA(k: number | undefined, d: number | undefined, a: number | undefined) {
    if (k === undefined && d === undefined && a === undefined)
      return 0;
  
    if (k === undefined)
      k = 0;
  
    if (d === undefined)
      d = 0;
  
    if (a === undefined)
      a = 0;
  
    let num = (k + a)
    let den = Math.max(1, d)
    let ratio = num / den
    return ratio.toFixed(3);
  }
  function CalcWL(w: number | undefined, l: number | undefined) {
    if (w === undefined || l === undefined || w === null || l === null || (l === 0 && w === 0))
      return 0;
  
    let ratio = w / (w + l) * 100;
    return ratio.toFixed(2)
  }

  return {
    u_def, // empty user object
    mu_def, // empty matched user object
    gs_def, // empty game stats object
    ls_def, // empty Leage Info object
    csgo_def, // empty CSGO Info object

    bu_def, // blocked user message object.

    TempList,
    TempUserReports,
    TempReport,

    //Parameter Enums for searching matches
    GamesParam,
    LangParam,
    RoleParam,

    getNowAsUTC,
    getLocalAsUTC,
    utcToPrettyDate,
    stringToPrettyDate,
    dateToPrettyDate,

    CalcKDA,
    CalcWL,

    SOURCE_IP: API_DOMAIN,
    ICON_PATH,
    NO_IMG_PATH,
    BASE_IMG_PATH,
    LOL_FLAT_PATH,
    LOL_ICON_PATH,
  };
}
