import React, { useState } from 'react'
import './block-report.css'
import PopupBox from './popup-box';
import ReportBox from './report-box';

type ReportButtonProps = {
  uToReport: string;
  setToken: CallableFunction;
  token: string;
}
export default function ReportButton({uToReport, setToken, token}: ReportButtonProps) {

  const [open, setOpen] = useState(false);
  function toggleReportBox(){
    setOpen(!open)
  }

  return (
    <>
      <div className='report-button' onClick={() => {setOpen(true)}}>Report User</div>

      {open &&

        <PopupBox toggleOpen={toggleReportBox} children={<ReportBox uToReport={uToReport} setToken={setToken} token={token} toggleOpen={toggleReportBox}/>}/>

      }

    </>
  )
}
