import React, { useState} from "react";
import "./game-link-box.css"
import { BsPlusCircleDotted } from "react-icons/bs";
import PopupBox from "../Common/popup-box";
import GameLinkForm from "./game-link-form";
import constants from "../../constants";
const { LOL_FLAT_PATH } = constants();

type GameLinkProps = {
    setToken: CallableFunction;
    token: string;
    user: UserObject;
    options: Game[];
}


export default function GameLinkBox({ setToken, token, user, options }: GameLinkProps) {
    function toggleReportBox() {
        setShowPicker(!showPicker)
    }
    const [showPicker, setShowPicker] = useState(false);
        return (
            <div className="addGame">
                <h2 id="link-header">
                    Linked Games
                </h2>
                <div id="add-games-wrapper">
                    {(user.games.lol !== undefined) ?
                        <img
                            alt="temp_ League of Legends"
                            id='plays-league-img'
                            src={LOL_FLAT_PATH}
                        />
                        :
                        <></>
                    }

                    {<div onClick={() => setShowPicker(true)}><BsPlusCircleDotted size="100px" id="add-game-icon"></BsPlusCircleDotted></div> // TODO: FIND AN ADDER LOGO 
                    }
                </div>
                <div id="linkContainer">{showPicker && <PopupBox toggleOpen={toggleReportBox} children={<GameLinkForm toggleOpen={toggleReportBox} setToken={setToken} user={user} token={token} options={options} />}></PopupBox>}</div>
            </div>
        );
}