import React, { useEffect, useState, } from 'react';
import './App.css';
import './pages/style.css';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';

import Home from './pages/home';

import Credentials from './pages/credentials';

import Profile from './pages/profile';
import Matches from './pages/matches';
import Messages from './pages/messages';
import Admin from './pages/admin';
import Verifier from './pages/verifier';
import useToken from "./useToken";
import constants from "./constants"
import Edit from './pages/edit';
import Reset from './pages/reset';

const {SOURCE_IP} = constants()

//MOVE TO A CONSTANTS FILE?
function refreshPage() {
    window.location.reload();
}

function App() {
    const [isLoadingMain, setLoading] = useState(true);
    const { token, removeToken, setToken } = useToken()
    const [isAdmin, setIsAdmin] = useState(false)
    const [isVerified, setIsVerified] = useState(true)
    const [userEmail, setUserEmail] = useState("")

    //FUNCTION TO CHECK IF THE TOKEN IS EXPIRED (BUSTED WAY OF PREVENTING 
    //THE DEV ENVIRONMENT FROM CALLING THE API TWICE, CHANGE LATER?)
    

    useEffect(() => {
        if (token) {
            axios({
                method: "GET",
                url: SOURCE_IP + "/profile",
                headers: {
                    Authorization: "Bearer " + token
                }
            }).then((response) => {
                //check if token is refreshed here
                if (response.data.access_token) {
                    setToken(response.data.access_token)
                    console.log("TOKEN FOUND")
                }

                if (response.data.admin) {
                    console.log("USER IS AN ADMIN")
                    setIsAdmin(response.data.admin)
                }

                if(response.data.verified === false)
                {
                    setIsVerified(false)
                    setUserEmail(response.data.email)
                }

                console.log(response)
                setLoading(false)
                return;
            }).catch((error) => {
                if (error.data && error.data.access_token) {
                    setToken(error.data.access_token)
                    console.log("TOKEN FOUND")
                }
                //PUT THE TOKEN REMOVAL ON THE PAGES??
                if (error.response) {
                    //maybe remove console.logs later
                    console.log(error.response)
                    console.log("lostToken")
                }
                removeToken()
                console.log("LOGGING OUT")
                refreshPage()
                setLoading(false)
            })

        }else{
            setLoading(false)
        }
    }, [token, removeToken, setToken])




    if (isLoadingMain) {
        return <div className="Loading"></div>;
    } else {
        return (
            <Router>

                {/**
                 * <PopupBox> <StatBox gameObject={undefined} /> </ PopupBox>
                 */}

                <Header token={token} removeToken={removeToken} admin={isAdmin} isVerified={isVerified} userEmail={userEmail}/>


                {!token && token !== "" && token !== undefined ?
                    <Routes>
                        <Route path='/' element={<Home />}></Route>
                        <Route path='/login' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/signup' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/forgot' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/reset/*' element={<Reset setToken={setToken}/>} />
                        <Route path='/profile/*' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/edit' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/matches' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/messages' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/admin/*' element={<Credentials isLoggedIn={false} setToken={setToken} />} />
                        <Route path='/emailverify/*' element={<Verifier setToken={setToken} setIsVerified={setIsVerified}/>} />
                    </Routes> : (
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/login' element={<Credentials isLoggedIn={true} setToken={setToken} />} />
                            <Route path='/signup' element={<Credentials isLoggedIn={true} setToken={setToken} />} />
                            <Route path='/forgot' element={<Credentials isLoggedIn={true} setToken={setToken} />} />
                            <Route path='/reset/*' element={<Credentials isLoggedIn={true} setToken={setToken} />} />
                            <Route path='/profile/*' element={<Profile isLoggedIn={true} setToken={setToken} token={token} />} />
                            <Route path='/edit' element={<Edit setToken={setToken} token={token} />} />
                            <Route path='/matches' element={<Matches setToken={setToken} token={token} />} />
                            <Route path='/messages' element={<Messages setToken={setToken} token={token} />} />
                            <Route path='/admin/*' element={<Admin isAdmin={isAdmin} isLoggedIn={true} setToken={setToken} token={token} />} />
                            <Route path='/emailverify/*' element={<Verifier setToken={setToken} setIsVerified={setIsVerified} />} />
                        </Routes>
                    )
                }

                <Footer />

            </Router>
        )
    }

}

export default App;
