import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';


import LoginBox from '../components/Login/login-box';
import SignupBox from '../components/Signup/signup-box';
import ForgotBox from "../components/Forgot/forgot-box";


type CredentialProps = {
    isLoggedIn: boolean;
    setToken: CallableFunction;
}

export default function Credentials({ isLoggedIn, setToken }: CredentialProps) {
    const navigate = useNavigate();

    function DeterminePage(logged: boolean) {
        const location = useLocation();

        if (logged) {
            console.log('logged is: ' + logged)
            return (
                <>
                    {/**Redirect to user profile if already logged in. (Set nav location to global const?) */}
                    {navigate("/profile")}
                </>
            );
        } else {
            if (location.pathname.split("/").pop()?.toLowerCase() === "forgot") {
                return (
                    <>
                        <ForgotBox />
                    </>
                );
            } else if (location.pathname.split("/").pop()?.toLowerCase() === "signup") {
                return (
                    <>
                        <SignupBox setToken={setToken} />
                    </>
                );
            } else {
                return (
                    <>
                        <LoginBox setToken={setToken} />
                    </>
                )
            }
        }
    }


    return (
        <>

                <div className="credentials-body">
                    {/**Use url and login token to determine behavior for page */}
                    {DeterminePage(isLoggedIn)}
                </div>

        </>

    )



}