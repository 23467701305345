import React from 'react'
import { useNavigate } from 'react-router-dom'
import constants from '../../constants'
import BackButton from '../Common/back-button'

const { TempList, utcToPrettyDate } = constants()


type ReportedListProps = {
    fullReportList: ReportList;
}
export default function ReportedList({ fullReportList }: ReportedListProps) {
    const navigate = useNavigate()

    console.log(fullReportList)
    console.log(TempList)
    console.log(fullReportList.list[0])


    return (
        <>
            <div className='admin-header'>
                <h1>Active Reports</h1>
                <BackButton dir='/admin' />
            </div>

            <table className='reported-users'>
                <thead className='reported-users-head'>
                    <tr>
                        <th>Offending User</th>
                        <th>Report Count</th>
                        <th>Last Report</th>
                        <th></th>
                    </tr>
                </thead>

                {/** Make each table row to a user here*/}
                <tbody>
                    {
                        fullReportList ?
                            (Object.keys(fullReportList.list) as Array<keyof typeof fullReportList.list>).map((key, index) => {

                                return (
                                    <tr style={{ backgroundColor: index % 2 === 0 ? "#666666" : "#888888" }}>
                                        <td>{fullReportList.list[index].name}</td>
                                        <td>{fullReportList.list[index].reports.length}</td>
                                        <td>{utcToPrettyDate(fullReportList.list[index].reports[0].timestamp * 1000)}</td>
                                        <td><div onClick={() => navigate('/admin/useroffences', { state: { reportList: fullReportList.list[index] } })} className='view-reports'>View</div></td>
                                    </tr>
                                )
                            })

                            :
                            <tr style={{ backgroundColor: "#666666"  }}>
                                <td>No current reports</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                    }
                </tbody>
            </table>
        </>
    )
}
