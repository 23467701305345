import React from 'react'
import './back-button.css'
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
    dir : string;
}
export default function BackButton({dir}: BackButtonProps) {

    const navigate = useNavigate()

  return (
    <div className='back-button btn-backdrop' onClick={() => {navigate(dir)}}>Back</div>
  )
}
