import React from 'react';
import constants from "../../constants"
const {dateToPrettyDate} = constants()

type MessageProps = {
    data: Msg
}
export default function Message({data} : MessageProps) {

    
    if (data.sent === true) {
        return (
            <div className="user-sent">
                <div className="msg-text">
                    {data.message}
                </div>
                <div className="msg-time">
                    Sent {dateToPrettyDate(data.timestamp)}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="user-received">
                <div className="msg-text">
                    {data.message}
                </div>
                <div className="msg-time">
                    Received {dateToPrettyDate(data.timestamp)}
                </div>
            </div >
        )
    }
}