import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import ProfileEditor from "../components/Profile/profile-editor";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import constants from "../constants"
const {SOURCE_IP, u_def} = constants()

type ProfileProps = {
    setToken: CallableFunction;
    token: string;
}
export default function Edit({ setToken, token }: ProfileProps) {

    const [userData, setUser] = useState<UserObject>(u_def);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        const loadUser = async () => {
                await axios({
                    method: "GET",
                    url: SOURCE_IP + "/profile",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        getgames: "yes",
                    }
                }).then((response) => {
                    if (response.data.access_token) {
                        setToken(response.data.access_token)
                        console.log("TOKEN FOUND")
                    }
                    const gs1: GameStats = {
                        lol: undefined,
                        csgo: undefined,
                    }
                    if (Object.keys(response.data.lol).length <= 0) {
                        gs1.csgo = response.data.csgo
                    } else {
                        gs1.lol = response.data.lol;
                        gs1.csgo = response.data.csgo;
                    }
                    const u1: UserObject = {
                        uid: 1,
                        email: response.data.email,
                        discord: response.data.discord_name,
                        language: response.data.language,
                        gender: response.data.gender,
                        username: response.data.profile_name,
                        img: "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-256x256-q0fen40c.png",
                        bio: response.data.description,
                        games: gs1,
                        birth_date: response.data.birth_date,
                        isVerified: response.data.verified,
                        last_online: response.data.last_login,
                    }
                    setUser(u1);
                    setLoading(false);
                    console.log(u1.username);
                    console.log("hello");
                }).catch((error) => {
                    if (error.data && error.data.access_token) {
                        setToken(error.data.access_token)
                        console.log("TOKEN FOUND")
                    }
                    setLoading(false);
                    if (error.response.status===403) {
                            return <Navigate to='/'></Navigate>
                    }
                })
        }
         loadUser();
    }, [token, setToken]);

    function DeterminePage() {
            if (userData !== undefined) {
                    return (
                        <>
                            <ProfileEditor user={userData} token={token} setToken={setToken}/>
                        </>
                    );
            }
    }

    if (isLoading) {
        return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
    }


    return (
        <>
            <div className="edit-page">
                <div className="editor-body">
                    {/**Use url and login token to determine behavior for page */}
                    {DeterminePage()}
                </div>
            </div>

        </>

    );

}

