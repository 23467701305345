import React, { useState } from "react";
import "./forgot-box.css"
import axios from "axios";
import constants from "../../constants"
const {SOURCE_IP} = constants()

export default function ForgotBox() {

  const [forgotMsg, setMsg] = useState('')
  const [resetButtonText, setResetText] = useState("Send Reset Email")

  //login constant that submits login request and sets token
  const sendReset = async (event: React.FormEvent<HTMLFormElement>) => {
    setResetText("Loading...")
    event.preventDefault();

    const { email,} = event.target as typeof event.target & {
      email: { value: string }
    }

    axios({
      method: "POST",
      url: SOURCE_IP + "/submitreset",
      headers: {
        email: email.value,
      }
    }).then((response) => {
      if (response.status === 201) {
        setResetText("Send reset email")
        setMsg("Email sent! Please check your spam inbox if you do not see it in the next few minutes.")
      }
    }).catch((error) => {
      if(error.response) {
        if (error.response.data.msg === "Email does not match any accounts") {
          setResetText("Send reset email")
          setMsg("Email not associated with a user. Please double check your spelling and try again.")
        } else {
          setResetText("Send reset email")
          setMsg("Email not verified. A verification email has been resent.")

          console.log(email.value)
          axios({
            method: "POST",
            url: SOURCE_IP + "/submitverification",
            headers: {
              email: email.value,
            }
          }).then((response) => {

          }).catch((error) => {
            
          })
        }
      }
    })
  }


  return (
    <>
      <div className="login-box">
        <h2>Reset Password</h2>
        <form onSubmit={sendReset}>
          <div className="forgdiv">
            <label className="forgfield">
              Email
              <input className="resetinput" type="email" name="email" />
            </label>
          </div>
          <br></br>
          <input className="loginbutton btn-backdrop" id="resetbutton" type="submit" value={resetButtonText} />
          <div className="forgotmsg">
            <label>
              {forgotMsg}
            </label>
          </div>
        </form>
      </div>
    </>
  );
}