import React, { useState } from "react";
import "./login-box.css"
import axios from "axios";
import {PulseLoader} from "react-spinners"
import constants from "../../constants"
const {SOURCE_IP} = constants()

type LoginProps = {
  setToken: CallableFunction
}
export default function LoginBox({ setToken }: LoginProps) {

  const [loginMsg, setMsg] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [emailField, setEmailField] = useState('')

  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setEmailField(event.target.value);
  };

  //login constant that submits login request and sets token
  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password } = event.target as typeof event.target & {
      email: { value: string }
      password: { value: string }
    }
    setLoading(true);
    console.log(SOURCE_IP + "/log_in")
    axios({
      method: "POST",
      url: SOURCE_IP + "/log_in",
      headers: {
        email: email.value,
        password: password.value
      }
    }).then((response) => {
      setLoading(false);
      setToken(response.data.access_token)
    }).catch((error) => {
      if (error.response) {
        if (error.response.data.msg === "User is suspended") {
          setMsg("User account is currently banned.");
        } else {
          setMsg("Invalid username or password!")
        }
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
      }
      setLoading(false);
    })

  }

  if (!isLoading) {
  return (
    <>
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="regdiv">
            <label className="regfield">
              Email
              <input className="forminput" type="email" name="email" defaultValue={emailField} onChange={handleChange} />
            </label>
          </div>
          <div className="regdiv">
            <label className="regfield">
              Password
              <input className="forminput" type="password" name="password" />
            </label>
          </div>
          <div className="forgotbox">
            {<div><br></br>
              <div className="loginerrmsg">
                <label>
                  {loginMsg}
                </label>
              </div>Forgot your password? <a className="logtext" href="/forgot">Reset</a> it here!</div>}
          </div>
          <br />
          <div className="loginbutdiv">
            <input className="loginbutton btn-backdrop" type="submit" value="Log in" /> &nbsp;&nbsp;
            <br />
            <div className="regbox">
              No account? <a className="regtext" href="/signup">Register</a> instead!
            </div>
          </div>
        </form>
      </div>
    </>
  );
  } else {
    return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
  }
}