import React from 'react';


export default function Home() {
    return (
      <div className='page'>
        <div className='title'>
          <h1>Welcome to QueueUp!</h1>
        </div>
        <div className='home-body'>
          Welcome to QueueUp! The website to help League players find a partner
          that compliments their play style! Sign up now to find a player that
          will help you fight your way to the top.
        </div>
      </div>
    )
};