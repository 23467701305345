import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate} from 'react-router-dom'
import axios from "axios";
import "../components/Admin/admin.css"
import "../App.css"
import ReportedList from '../components/Admin/reported-list'
import ReportedUser from '../components/Admin/reported-user'

import constants from '../constants'
import AdminMenu from '../components/Admin/admin-menu';
import BannedList from '../components/Admin/banned-list';
import {PulseLoader} from "react-spinners";

const { TempList, SOURCE_IP } = constants()
type AdminProps = {
    isAdmin : boolean;
    isLoggedIn: boolean;
    setToken: CallableFunction;
    token: string;
}
export default function Admin({ isAdmin, isLoggedIn, setToken, token }: AdminProps) {
    const location = useLocation()
    const navigate = useNavigate()


    const [isLoading, setLoading] = useState(true)
    const [reportedUsers, setReportedUsers] = useState<ReportList>(TempList)

    useEffect(() => {
        const getReportedUsers = async () => {
            await axios({
                method: "GET",
                url: SOURCE_IP + "/admin",
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                //console.log(response.data)
                if (response.data.access_token) {
                    setToken(response.data.access_token)
                    console.log("TOKEN FOUND")
                }
                reportedUsers.list = response.data
                setReportedUsers(reportedUsers) // list of users, pre compatability checking (at least right now. Compatability check location is still unknown.)
                setLoading(false);
            }).catch((error) => {
                if (error.data && error.data.access_token) {
                    setToken(error.data.access_token)
                    console.log("TOKEN FOUND")
                }
                setLoading(false);
            })
        }
        getReportedUsers()
    })

    function DeterminePage(logged: boolean) {
        /**Logic to check if admin here, then the page to display? /userreports, /bannedusers, /reportedusers, etc. */
        
        if(!isAdmin){
            navigate("/")
            return
        }
        
        if (logged) {
            //if (userData !== undefined) {

            const page = location.pathname.split("/").pop()?.toLowerCase();
            console.log(reportedUsers)
            if (page === "admin") {
                return (
                    <>
                        <div className='admin-wrapper'>
                            <AdminMenu />
                        </div>

                    </>
                );
            } else if (page === "unanswered") {
                return (
                    <>
                        <div className='admin-wrapper'>
                            <ReportedList fullReportList={reportedUsers} />
                        </div>
                    </>
                )
            } else if (page === "useroffences") {
                return (
                    <>
                        <div className='admin-wrapper'>
                            <ReportedUser setToken={setToken} token={token} />
                        </div>
                    </>
                )
            } else if (page === "bannedusers") {
                return (
                    <>
                        <div className='admin-wrapper'>
                            <BannedList token={token} setToken={setToken} />
                        </div>
                    </>
                )
            } else {
                navigate("/admin")
            }
            //}
        }
    }


    if (isLoading) {
        return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
    } else {

        return (
            <>
                <div className='page'>
                    {DeterminePage(isLoggedIn)}
                </div>
            </>

        );

    }
}
