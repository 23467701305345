import React, {useState} from 'react';
import axios from "axios";
import {useLocation} from 'react-router-dom';
import constants from "../constants"
const {SOURCE_IP} = constants()

type VerifierProps = {
    setToken: CallableFunction;
    setIsVerified: CallableFunction;
}

export default function Verifier({setToken, setIsVerified} : VerifierProps) {
    const location = useLocation();
    const [verMsg, setMsg] = useState('');
    const generatedLink = location.pathname.split("/").pop();
    console.log(generatedLink)

    const doVerifying = (generatedLink : string | undefined) => {
        if (generatedLink === undefined) {
            console.log("ERROR: undefined link")
        }
        axios({
            method: "POST",
            url: SOURCE_IP + "/verify",
            headers: {
              userlink: `${generatedLink}`
            }
          }).then((response) => {
            if (response.status === 201) {
                setMsg("Your account has been verified! You may now leave this page.")
                setIsVerified(true)
            }
          }).catch((error) => {
            if (error.response) {
              setMsg("There was an error verifying your account. Please make sure the link you've entered is correct and has not expired.")
              console.log(error.response.data)
              //console.log(error.response)
              //console.log(error.response.status)
              //console.log(error.response.headers)
            }
          })
    }

    doVerifying(generatedLink);

    return (
      <div className='ver-page'>
        <div className='title'>
          <h1>Email Verification</h1>
          <h2>{verMsg}</h2>
        </div>
      </div>
    )
};