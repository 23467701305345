import React, { ReactNode } from 'react'
import './popup-box.css'

type PopupProps = {
  children : ReactNode;
  toggleOpen: CallableFunction;
}
export default function PopupBox({children, toggleOpen} : PopupProps) {
  return (
    <>
    {/*<div className='popup-bg' onClick={() => toggleOpen()}>  */}
      <div className='popup-bg'>
        <div className='popup-wrapper'>
            {children}
        </div>
      </div>
    </>
  )
}
