import React, { useState } from 'react'
import constants from "../../constants"
import GeneralButton from '../Common/general-button';
const {utcToPrettyDate} = constants()

const imgSRC : string = "/downarrow.png"

type ReportProps = {
    report: ReportObject;
    showMsgCallback: CallableFunction;
    reportee: string;
}
export default function SingleReport({ report, showMsgCallback, reportee }: ReportProps) {

    const [open, setOpen] = useState(false);

    console.log(report.timestamp)
    console.log(reportee)
    return (
        <>
            <button className='report-box' onClick={() => setOpen(!open)}>
                <div className='report-timestamp'>
                    {utcToPrettyDate(report.timestamp * 1000)}
                </div>
                <img alt='temp_ See More' className='report-see-more' src={imgSRC}/>
            </button>
            {/** Toggle the display of the div below */}
            {open &&
                <div className='report-content'>
                    <span className='rf1'> Reported By: {report.reportee} </span>
                    <br />
                    <span className='rf2'> Type:  {report.type} </span>
                    <br />
                    <span className='rf3'> Comment: {report.content} </span>
                    <br />
                    <div id="btn-backdrop">
                    <GeneralButton lbl={"See conversation history."} diy={() => showMsgCallback(reportee)}></GeneralButton>
                    </div>
                </div>
            }
        </>

    )
}
