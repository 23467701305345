import React, { useState } from 'react'
import './block-report.css'
import axios from "axios";
import GeneralButton from './general-button';
import constants from "../../constants"
const {SOURCE_IP} = constants()

type ReportBoxProps = {
    uToReport: string;
    setToken: CallableFunction;
    token: string;
    toggleOpen: CallableFunction;
}
export default function ReportBox({ uToReport, setToken, token, toggleOpen }: ReportBoxProps) {
    let reportType: string = "NO TYPE"
    let comment: string = "NO COMMENT"

    const [taskCompleteness, SetTaskCompleteness] = useState(0);

    const submitReport = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const target = event.target as typeof event.target & {
            type: { value: string };
            comment: { value: string };
        };

        reportType = target.type.value
        comment = target.comment.value

        sendReport()
    }

    const sendReport = async () => {

        await axios({
            method: "POST",
            url: SOURCE_IP + "/report",
            headers: {
                Authorization: `Bearer ${token}`,
                reported: uToReport,
                type: reportType,
                content: comment
            }
        }).then((response) => {
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(response)
            //TELL USER REPORT HAS BEEN SUBMITTED
            SetTaskCompleteness(1)
        }).catch((error) => {
            if (error.data && error.data.access_token) {
                setToken(error.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(error)
            console.log(error.response.data.msg)
            if (error.response.data.msg === "1062") {
                //TELL USER ABOUT REPORT
                SetTaskCompleteness(2)
            }
        })
        console.log(taskCompleteness)
    }


    if (taskCompleteness === 0) { // Initial Box, no user input so far.
        return (
            <>
                <div className='report-popup-box'>
                    <h2 className='report-box-title'>Report Player</h2>
                    <form onSubmit={submitReport}>
                        <label>Report Type</label>
                        <br />
                        <select id='type'>
                            <option key="harrassment" value="Harrassment">Harrassment</option>
                            <option key="spam" value="Spam">Spam</option>
                        </select>
                        <br />
                        <br />
                        <label>Additional Details</label>
                        <br />
                        <textarea id='comment' className='report-comment-box'></textarea>
                        <br />

                        <input className='submit-report btn-backdrop' type='submit'></input>
                        <div className='back-btn-wrapper'>
                            <GeneralButton lbl='Back' diy={toggleOpen} />
                        </div>
                        
                    </form>

                </div>
            </>
        )
    }
    else if (taskCompleteness === 1) { // Successful report, informs users and propmts them to close the box
        return (
            <>
                <div className='report-popup-box'>
                    <h2 className='report-box-title'>Report Player</h2>
                    Your report has been received! Thank you for helping make QueueUp a safer place!
                    <div className='back-btn-wrapper'>
                            <GeneralButton lbl='OK' diy={toggleOpen} />
                        </div>
                </div>
            </>
        )
    } 
    else if(taskCompleteness === 2){ // Error, the user has already reported this account.
        return (
            <>
                <div className='report-popup-box'>
                    <h2 className='report-box-title'>Report Player</h2>
                    You seem to already have a pending report against this user. If you feel this is an error, please contact us by sending an email to help@queueup.app
                    <div className='back-btn-wrapper'>
                            <GeneralButton lbl='OK' diy={toggleOpen} />
                        </div>
                </div>
            </>
        )
    }else
        return (
            <>
                <div className='report-popup-box'>
                    <h2 className='report-box-title'>Report Player</h2>
                    <form onSubmit={submitReport}>
                        <label>Report Type</label>
                        <br />
                        <select id='type'>
                            <option key="harrassment" value="Harrassment">Harrassment</option>
                            <option key="spam" value="Spam">Spam</option>
                        </select>
                        <br />
                        <br />
                        <label>Additional Details</label>
                        <br />
                        <textarea id='comment' className='report-comment-box'></textarea>
                        <br />

                        <input type='submit'></input>
                        <div className='back-btn-wrapper'>
                            <GeneralButton lbl='Back' diy={toggleOpen} />
                        </div>
                    </form>

                </div>
            </>
        )
}
