import React, { useState, useEffect } from "react";
import "./profile-editor.css";
import "./game-link-box";
import axios from "axios";
import GameLinkBox from "./game-link-box";
import ImageUploader from "./image-upload-box";
import Defs from "../../constants"
import constants from "../../constants";
import { PulseLoader } from "react-spinners";
import PopupBox from "../Common/popup-box";
import GeneralButton from "../Common/general-button";
import AlertBox from "./alert-box";
const { NO_IMG_PATH, BASE_IMG_PATH, SOURCE_IP} = constants();

let langs = Defs().LangParam;

type ProfileProps = {
  setToken: CallableFunction;
  token: string;
  user: UserObject;
}

function CheckValidUserName(str: string) {
  var code, i, len;

  if (str.length === 0)
    return false

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export default function ProfileEditor({ setToken, token, user }: ProfileProps) {
  const [user_name, userIsValid] = useState('Valid username!')
  const [options, setOptions] = useState<Game[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [linkedMsg, setLinkedMsg] = useState('');
  const formData: any = new FormData();

  let userImage: File | undefined = undefined;

  const replaceImage = (error: any) => {
    //replacement of broken Image
    error.target.src = NO_IMG_PATH
  }
  let imgUrl: string = BASE_IMG_PATH + user.username + ".png"

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      // Fetch data
      await axios({
        method: "GET",
        url: SOURCE_IP + "/games",
      }).then((response) => {
        const result = Object.values<Game>(response.data);
        let games: Game[] = [];
        result.forEach(val => {
          const g1: Game = {
            accent_color: val.accent_color,
            real_name: val.real_name,
          }
          games.push(g1);
        })
        setOptions(games);
      }
      )
      // Store results in the results array
    }
    fetchData();
    setLoading(false);
  }, [token]);
  console.log(options);

  const [showUploader, setShowUploader] = useState(false)

    function toggleUploader() {
        setShowUploader(!showUploader)
    }

    const [showLinked, setShowLinked] = useState(false)

    function toggleLinked() {
        setShowLinked(!showLinked)
    }


  const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    //const { token, removeToken, setToken } = useToken();
    event.preventDefault();
    setLoading(true);
    if (userImage !== undefined) {
      formData.append("image", userImage)
    }
    const { email, username, dob, bio, gender, dtag, language } = event.target as typeof event.target & {
        //possible changes needed to handle email here?
      email: { value: string }
      password: { value: string }
      username: { value: string }
      dob: { value: string }
      bio: { value: string }
      gender: { value: string }
      dtag: { value: string }
      language: { value: string }
    }
    axios({
      method: "POST",
      url: SOURCE_IP + "/update",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        email: email.value,
        profile: username.value,
        birth: dob.value,
        discord: dtag.value,
        description: bio.value,
        gender: gender.value,
        language: language.value,
        "Content-Type": 'multipart/form-data'
      }
    }).then((response) => {
      console.log(response)
      if (response.data.access_token) {
        setToken(response.data.access_token)
        console.log("TOKEN FOUND")
      }
      setLinkedMsg("Account Updated!")
      toggleLinked();
      setLoading(false);
    }).catch((error) => {
      console.log(error)
      if (error.response) {
        if (error.data && error.data.access_token) {
          setToken(error.data.access_token)
          console.log("TOKEN FOUND")
        }
        setLinkedMsg(error.response.data.msg)
        toggleLinked();
        console.log(error.response.data)
        setLoading(false);
      }
    })

  }
    return (
      <>
        <div className="profile-editor">
          <h2 id="account-info-header">
            Profile Information
          </h2>
          <div id="edit-img-wrapper">
                <div id="edit-img">
                    <img alt="temp_ Your User Avatar" id="edit-img-src" src={imgUrl} onError={replaceImage} />
                </div>
          </div>
          <div id="uploader-container">{showUploader && <PopupBox toggleOpen={toggleUploader} children={<ImageUploader toggleOpen={toggleUploader} setToken={setToken} user={user} token={token}/>}></PopupBox>}</div>
          <div id="upload-button"><GeneralButton lbl="Edit Picture" diy={toggleUploader}></GeneralButton></div>
          <form onSubmit={handleSignup}>
            <div>
              <label>
                <text className="regfield">Email</text>
                <input type="email" name="email" value={user.email} readOnly={true} />
              </label>
            </div>
            <div>
              <label>
                {" "}
              </label>
            </div>

            <div>
              <label>
                <text className="regfield">Username</text>
                <input type="text" name="username" defaultValue={user.username} onChange={(e) => { CheckValidUserName(e.target.value) ? userIsValid('Valid username!') : userIsValid('Username can only contain alphanumeric characters.') }} required />
              </label>
            </div>

            {
              user.birth_date &&
              <div>
                <label>
                  <text className="regfield">Birthdate</text>
                  <input type="date" name="dob" defaultValue={new Date(user.birth_date).toISOString().substring(0, 10)} required />
                </label>
              </div>
            }

            {
              !user.birth_date &&
              <div>
                <label>
                  <text className="regfield">Birthdate</text>
                  <input type="date" name="dob" defaultValue={0} required />
                </label>
              </div>
            }

            <div>
              <label>
                <text className="regfield" id="bio-header">Bio</text>
                <textarea id="bio-field" name="bio" maxLength={255} defaultValue={user.bio} />
              </label>
            </div>
            <div>
              <label>
                <text className="regfield">Gender</text>
                <select name="gender" id="gender" defaultValue={user.gender} required>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Non-Binary/Other</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                <text className="regfield">Discord Tag</text>
                <input type="text" name="dtag" maxLength={255} defaultValue={user.discord} />
              </label>
            </div>
            <div>
              <label>
                <text className="regfield">Language</text>
                <select name="language" required>
                  {(Object.keys(langs) as Array<keyof typeof langs>).map((key, index) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div>
              {/**<input type="submit" value="Update!" disabled={!(pass === "OK")} /> &nbsp;&nbsp;*/}
              {isLoading ? <div id="loader"><PulseLoader color="#FFFFFF" size="10px" margin="1px"></PulseLoader></div> : <input id="submitupdate" disabled={!(user_name === "Valid username!")} type="submit" value="Update!"/>}
            </div>
            <div>
            {showLinked && <PopupBox toggleOpen={toggleLinked} children={<AlertBox toggleOpen={toggleLinked} text={linkedMsg}/>}></PopupBox>}
            </div>
          </form>
          {/**We need a way to set the token inside the COMPONENT
             * Currently it TRIES to set it in the imported ts file.
             * However it does not work and needs to be set inside the 
             * component with the passed function in props to prevent issues.
             * ((setToken is passed from App.tsx))
             */}
        </div>
        <GameLinkBox setToken={setToken} user={user} token={token} options={options} />
      </>
    );
} 