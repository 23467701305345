import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import SingleReport from './single-report'
import BackButton from '../Common/back-button'
import BanButton from './ban-button'
import PopupBox from '../Common/popup-box'
import ReportMsgBox from './report-convo-history'

type MainReportsProps = {
    userReports?: UserSpecificReports;
    setToken: CallableFunction;
    token: string;
}
export default function ReportedUser({ userReports, setToken, token }: MainReportsProps) {
    const { state } = useLocation()

    const { reportList } = state
    console.log(state)

    const [msgOpen, setMsgOpen] = useState(false);
    const [msgToView, setMsgToView] = useState("");

    if (state === undefined || state === null) {
        return (
            <>
                Error!
            </>
        )
    }

    function toggleMessagesBox(umsg: string) {
        setMsgToView(umsg)
        setMsgOpen(!msgOpen)
    }

    return (
        <>
            <div className='admin-header'>
                <h1>Reports for {reportList.name}</h1>
                <BanButton uToReport={reportList.name} setToken={setToken} token={token} />
                &nbsp;&nbsp;&nbsp;
                <BackButton dir='/admin/unanswered' />
            </div>
            <div className='single-user-reports'>


                {(Object.keys(reportList.reports) as Array<keyof typeof reportList.reports>).map((key, index) => {
                    return (
                        <SingleReport report={reportList.reports[index]} showMsgCallback={toggleMessagesBox} reportee={reportList.reports[index].reportee} />
                    )
                })
                }
            </div>

            {msgOpen &&

                <PopupBox toggleOpen={toggleMessagesBox} children={<ReportMsgBox token={token} setToken={setToken} closeBox={toggleMessagesBox} user={reportList.name} reportee={msgToView} />} />

            }

        </>
    )
}
