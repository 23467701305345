import React from 'react';
import "./statbox.css";
import constants from '../../constants';
const { LOL_ICON_PATH,  CalcKDA, CalcWL} = constants()


type StatBoxProps = {
  gameObject?: LeagueStats
}

const getRole: { [key: string]: string } = {
  "JUNGLE": "Jungle",
  "TOP": "Top",
  "MIDDLE": "Mid",
  "BOTTOM": "ADC",
  "UTILITY": "Support",
  "NONE": "None",
  HAS_NOT_PLAYED: "No games played!",
}

const HAS_NOT_PLAYED: string = "HAS_NOT_PLAYED";

export default function StatBox({ gameObject }: StatBoxProps) {

  const containerStyles = {
    height: 20,
    width: '80%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    display: "inline-block"
  }

  const fillerStyles = {
    height: '100%',
    width: `${gameObject?.rr}%`,
    backgroundColor: "#F9C846",
    borderRadius: 'inherit',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  //console.log(gameObject)
  if (gameObject === undefined || Object.keys(gameObject).length === 0) {
    return (
      <>
      </>
    )
  } else {
    if (gameObject.flex_role === null) {
      gameObject.flex_role = "NONE";
    }
    if (gameObject.preferred_role === null) {
      gameObject.preferred_role = HAS_NOT_PLAYED;
    }
    if (gameObject.tier === null) {
      gameObject.tier = "N/A"
    }
    return (
      <>
        <div className="game-stats-box">
          <div className='game-stats-box-header'>
            <img
              alt="temp_ GAME League of Legends"
              className='stats-box-game-img'
              src={LOL_ICON_PATH}></img>
            <div className='stats-box-title-container'>
              League of Legends
            </div>
          </div>
          <div className='game-stats-box-body'>
            <div className="stat-line">Level: {gameObject.level}</div>
            <div className="stat-line">Rank: {gameObject.rank === null || gameObject.tier === null ? "N/A" : gameObject.tier + " " + gameObject.rank}</div>
            <div className="stat-line">RR: {gameObject.rr !== null ? <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{}</span>
      </div>
    </div> : "N/A"}</div>
            <div className="stat-line">Preferred Role: {getRole[gameObject.preferred_role!]}</div>
            <div className="stat-line">Flex Role: {getRole[gameObject.flex_role!]}</div>
            <div className="stat-line">Winrate: {gameObject.wins === null || gameObject.losses === null ? "N/A" : CalcWL(gameObject.wins, gameObject.losses) + "%"}</div>
            <div className="stat-line">KDA: {CalcKDA(gameObject.avg_kills, gameObject.avg_deaths, gameObject.avg_assists)}</div>
            <div className="stat-line">Average Gold Per Minute: {gameObject.avg_goldpm ? gameObject.avg_goldpm.toFixed(3) : "0.000"}</div>
            <div className="stat-line">Average Vision Per Minute: {gameObject.avg_visionscorepm ? gameObject.avg_visionscorepm.toFixed(3) : "0.000"}</div>
          </div>
        </div>
      </>
    )
  }
}
