import React from "react";
import "./image-upload-box.css"
import GeneralButton from "../Common/general-button";

type AlertBoxProps = {
    toggleOpen: CallableFunction;
    text: string;
}

export default function AlertBox({ toggleOpen, text }: AlertBoxProps) {

    //Where the statbox is declared, place component that takes the userData.games as a prop and displays unique box for each linked game.
    return (
        <>
            <div id="upload-wrapper">
                <h2>{text}</h2>
                <div id="style-button-back"><GeneralButton lbl="Back" diy={toggleOpen}></GeneralButton></div>
            </div>
        </>
    );
}