import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from 'react-router-dom';
import ProfileBox from "../components/Profile/profile-box";
import axios from "axios";
import constants from "../constants";
import "./style.css"
import "./../App.css"
import { PulseLoader } from "react-spinners";

type ProfileProps = {
    isLoggedIn: boolean;
    setToken: CallableFunction;
    token: string;
}
export default function Profile({ isLoggedIn, setToken, token }: ProfileProps) {
    const location = useLocation();

    const { SOURCE_IP } = constants();

    const [userData, setUser] = useState<UserObject>();
    const [currMode, setCurrMode] = useState<number>();
    const [isLoading, setLoading] = useState(true);


    //Possibly move the axios calls to their own files an import here to make it more readable and modular???
    useEffect(() => {
        const loadUser = async () => {
            if (location.pathname.split("/").pop()?.toLowerCase() === "profile") {
                await axios({
                    method: "GET",
                    url: SOURCE_IP + "/profile",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        getgames: "yes"
                    }
                }).then((response) => {
                    if (response.data.access_token) {
                        setToken(response.data.access_token)
                        console.log("TOKEN FOUND")
                    }
                    // get catch any stats from games, and put into type struct
                    // if lol stats returns empty, make undefined
                    const gs1: GameStats = {
                        lol: undefined,
                        csgo: undefined,
                    }
                    if (Object.keys(response.data.lol).length <= 0) {
                        gs1.csgo = response.data.csgo
                    } else {
                        gs1.lol = response.data.lol;
                        gs1.csgo = response.data.csgo;
                    }
                    const u1: UserObject = {
                        uid: 1,
                        email: response.data.email,
                        gender: response.data.gender,
                        username: response.data.profile_name,
                        img: "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-256x256-q0fen40c.png",
                        bio: response.data.description,
                        games: gs1,
                        discord: response.data.discord_name,
                        language: response.data.language,
                        birth_date: response.data.birth_date,
                        isVerified: response.data.verified,
                        last_online: response.data.last_login,
                    }
                    setUser(u1);
                    setLoading(false);
                    console.log(response);
                }).catch((error) => {
                    if (error.data && error.data.access_token) {
                        setToken(error.data.access_token)
                        console.log("TOKEN FOUND")
                    }
                    setCurrMode(2);
                    setLoading(false);
                    if (error.response.status === 403) {
                        return <Navigate to='/'></Navigate>
                    }
                })
            } else {
                await axios({
                    method: "GET",
                    url: SOURCE_IP + "/profile",
                    headers: {
                        profile: location.pathname.split("/").pop()?.toLowerCase(),
                        getgames: ""
                    }
                }).then((response) => {
                    const gs1: GameStats = {
                        lol: response.data.lol,
                        csgo: response.data.csgo
                    }
                    const u1: UserObject = {
                        uid: 1,
                        email: response.data.email,
                        gender: "",
                        username: response.data.profile_name,
                        img: "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-256x256-q0fen40c.png",
                        bio: response.data.description,
                        games: gs1,
                        discord: "",
                        language: "",
                        birth_date: response.data.birth_date,
                        isVerified: false,
                        last_online: response.data.last_login,
                    }
                    setUser(u1);
                    setLoading(false);
                    console.log(response);
                }).catch((error) => {
                    if (error.data && error.data.access_token) {
                        setToken(error.data.access_token)
                        console.log("TOKEN FOUND")
                    }
                    setCurrMode(2);
                    setLoading(false);
                    if (error.response.status === 403) {
                        console.log("x");
                        return <Navigate to="/"></Navigate>
                    }
                })
            }
        }
        loadUser();
    }, [SOURCE_IP, location.pathname, setToken, token]); // Fix for ts >:(    ((Basically this needs to have the things from outside the useEffect being used INSIDE the useEffect))

    function DeterminePage(logged: boolean) {
        if (logged) {
            if (currMode === 2) {
                return <Navigate to="/" />
            }
            if (userData !== undefined) {
                const userToView = location.pathname.split("/").pop()?.toLowerCase();

                // Removed League Stats Prop. All game stats are passed in the games field in the userData object.
                // See types.d.s to see more
                if (userToView === "profile") {
                    return (
                        <> 
                            <ProfileBox userData={userData} canEdit={true}/>
                        </>
                    );
                } else {
                    return (
                        <> 
                            <ProfileBox userData={userData} canEdit={false} />
                        </>
                    )
                }
            }
        }
    }


    if (isLoading) {
        return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
    } else {
        if (userData === undefined) {
            return <div className="err-msg">No user found at this profile. Check your spelling?</div>;
        }
        else {
            return (
                <>
                    <div className="page">
                        <div className="profile-body">
                            {/**Use url and login token to determine behavior for page */}
                            {DeterminePage(isLoggedIn)}
                        </div>
                    </div>

                </>

            );
        }
    }



}