import React, { useState } from 'react'
import PopupBox from '../Common/popup-box';
import BanBox from './ban-box';

type BanButtonProps = {
    uToReport: string;
    setToken: CallableFunction;
    token: string;
}
export default function BanButton({ uToReport, setToken, token }: BanButtonProps) {

    const [open, setOpen] = useState(false);
    function toggleReportBox() {
        setOpen(!open)
    }

    return (
        <>
            <div className='ban-button btn-backdrop' onClick={() => { setOpen(true) }}>Ban User</div>

            {open &&

                <PopupBox toggleOpen={toggleReportBox} children={<BanBox uToBan={uToReport} setToken={setToken} token={token} toggleOpen={toggleReportBox} />} />

            }
        </>
    )
}
