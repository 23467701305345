import React, { useState } from 'react'
import axios from "axios";
import GeneralButton from '../Common/general-button';
import constants from "../../constants"
const {getLocalAsUTC, SOURCE_IP} = constants()

const MAX_BAN = 2147299200

type BanBoxProps = {
    uToBan: string;
    setToken: CallableFunction;
    token: string;
    toggleOpen: CallableFunction;
}
export default function BanBox({ uToBan, setToken, token, toggleOpen }: BanBoxProps) {

    const [permaBan, setPermaBan] = useState(false)
    const [taskCompleteness, SetTaskCompleteness] = useState(0);

    let utc_ban: number

    const submitBan = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const target = event.target as typeof event.target & {
            timestamp: { value: Date };
        };

        let banDate = target.timestamp.value.toString()
        console.log(banDate)
        console.log(permaBan)

        utc_ban = getLocalAsUTC(new Date(target.timestamp.value))

        if (permaBan === true) {
            utc_ban = MAX_BAN
            console.log("TRUE")
        } else {
            console.log("FALSE")
            utc_ban = utc_ban / 1000
        }

        console.log(MAX_BAN)
        console.log(utc_ban)

        sendBan()
    }

    const sendBan = async () => {
        await axios({
            method: "POST",
            url: SOURCE_IP + "/set_suspension",
            headers: {
                Authorization: `Bearer ${token}`,
                timestamp: utc_ban,
                profile: uToBan,
            }
        }).then((response) => {
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(response)

            SetTaskCompleteness(1)
        }).catch((error) => {
            if (error.data && error.data.access_token) {
                setToken(error.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(error)
        })
    }

    if (taskCompleteness === 0) { // Initial Box, no user input so far.
        return (
            <>
                <div className='ban-popup-box'>
                    <h2 className='ban-box-title'>Suspend Player</h2>
                    <form onSubmit={submitBan}>
                        <label>Select a date to ban the user.</label>
                        <br />
                        <input type='date' name='timestamp' />
                        <br />
                        <br />

                        <label>OR: Ban user FOREVER</label>
                        <br />
                        <input type='checkbox' checked={permaBan} onChange={() => {
                            setPermaBan(!permaBan)
                        }} name='permaban' />
                        
                        <br />
                        <br />

                        <input type='submit'></input>
                        &nbsp;&nbsp;&nbsp;
                        <button onClick={() => toggleOpen()}>Back</button>
                    </form>

                </div>
            </>
        )
    }else if(taskCompleteness === 1){ // Successful ban, informs users and propmts them to close the box
        return (
            <>
                <div className='ban-popup-box'>
                    <h1 className='ban-box-title'>Suspend Player</h1>
                        Player has been banned successfully
                        <br />
                        <br />
                        
                        <a href="/admin/unanswered">
                            <div style={{width: "50%", margin: "auto"}}>
                                <GeneralButton lbl="Return"/>
                            </div>
                            </a>

                </div>
            </>
        )
    }else{
        return (
            <>
                <div className='ban-popup-box'>
                    <h2>Error</h2>
                    

                </div>
            </>
        )
    }
}
