import React from 'react';
import './match-box.css';
import Defs from "../../constants";
import {BsFillEnvelopeFill} from "react-icons/bs"

const CalcKDA = Defs().CalcKDA
const CalcWL = Defs().CalcWL
const StringToPrettyDate = Defs().stringToPrettyDate

const NO_IMG_PATH = Defs().NO_IMG_PATH
const BASE_IMG_PATH = Defs().BASE_IMG_PATH

const getRole: { [key: string]: string } = {
    "JUNGLE": "Jungle",
    "TOP": "Top",
    "MIDDLE": "Mid",
    "BOTTOM": "ADC",
    "UTILITY": "Support",
    "NONE": "None",
    HAS_NOT_PLAYED: "No games played!",
}

type MatchBoxProps = {
    userMatch: MatchedUserObject;
    token: string;
    setToken: CallableFunction;
    setUserToMessage : CallableFunction;
    openMessagePopup : CallableFunction;
}
export default function MatchBox({ userMatch, token, setToken, setUserToMessage, openMessagePopup }: MatchBoxProps) {
    
    const replaceImage = (error : any) => {
        //replacement of broken Image
        error.target.src = NO_IMG_PATH
    }
    let imgUrl : string = BASE_IMG_PATH + userMatch.profile_name + ".png"

    if (userMatch.lol !== undefined) {
        if (userMatch.lol.flex_role === null) {
            userMatch.lol.flex_role = "NONE";
        }
    }

    function OpenMessageBox() {
        setUserToMessage(userMatch.profile_name)
        openMessagePopup()
    }


    if (userMatch.lol === undefined)
        return (
            <>
                ERROR
            </>
        );


        const profString = "/profile/" + userMatch.profile_name

    return (

        <>
            <div className='match-wrapper'>
                {/** USER PROFILE PICTURE IS NOT SENT FROM API SING MATCHES ENDPOINT NEEDS USER IMG*/}

                <div className='match-top-row'>
                    <div className='match-username'>
                        <a href={profString} target="_blank" rel="noreferrer">{userMatch.profile_name}</a>
                    </div>

                    <div className='match-btn-holder'>
                        <div className='match-msg-btn' onClick={() => {OpenMessageBox()}}>
                            <BsFillEnvelopeFill id="envelopeIcon" size="40px"></BsFillEnvelopeFill>
                        </div>
                    </div>
                </div>

                <div className='match-mid-row'>
                    
                    <img alt="Found Match User Avatar" className='match-ico' src={imgUrl} onError={replaceImage}/>

                    <div className='middle-info-wrapper'>

                        <div className='middle-info-rank'>
                            {userMatch.lol.tier}&nbsp;{userMatch.lol.rank}
                        </div>

                        <div className='info-row-top'>
                            <div className='match-main-role mid-one'>
                                Preferred Role: <br />
                                <span className='small-font-size'>{getRole[userMatch.lol.preferred_role!]}</span>
                            </div>
                            <div className='match-kda mid-two'>
                             KDA:
                                <br />
                                <span className='small-font-size'>{CalcKDA(userMatch.lol.avg_kills, userMatch.lol.avg_deaths, userMatch.lol.avg_assists)}</span>
                            </div>
                        </div>

                        <div className='info-row-bot'>
                            <div className='match-flex-role mid-one'>
                                Flex Role: <br />
                                <span className='small-font-size'>{getRole[userMatch.lol.flex_role!]}</span>
                            </div>
                            <div className='match-wl mid-two'>
                             Winrate:
                                <br />
                                <span className='small-font-size'>{CalcWL(userMatch.lol.wins, userMatch.lol.losses)}%</span>
                            </div>
                        </div>


                    </div>
                </div>

                <div className='match-mid-footer'>
                    <div className='match-last-online'>
                        Last Online:&nbsp;{StringToPrettyDate(userMatch.last_login)}
                    </div>
                    <div className='match-language'>
                        Primary Language:&nbsp;{userMatch.language}
                    </div>
                </div>


                <div className='match-bio-row'>
                    <span className='large-font-size'>About: <br></br></span>
                    <span>{userMatch.description}</span>
                </div>
                
            </div>
        </>
    )
}