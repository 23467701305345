import React from 'react'
import './match-msg-box.css'
import MessageSender from '../Messages/MessageSender';
import GeneralButton from '../Common/general-button';

type MatchMessageBoxProps = {
    setToken: CallableFunction
    token: string;
    whoToMessage: string;
    closeBox: CallableFunction;
}
export default function MatchMessageBox({ setToken, token, whoToMessage, closeBox }: MatchMessageBoxProps) {

    return (
        <>
            <div className='match-msg-wrapper'>
                <h2>Send message to {whoToMessage}</h2>
                <div className='sender-container'>
                    <MessageSender setToken={setToken} token={token} sendTo={whoToMessage} doLoading={undefined} />
                </div>

                <div className='match-msg-close'>
                    <GeneralButton lbl="Close" diy={closeBox} />
                </div>
            </div>
        </>
    )
}
