import React, {useState} from "react";
import "./signup-box.css"
import axios from "axios";
import constants from "../../constants"
const {SOURCE_IP} = constants()

function CheckValidPass(str: string) {

  const passw = /^(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$%^&])[a-zA-Z\d!@#$%^&]{8,50}$/;
  if (!str.match(passw))
    return false;

  return true;
}

function CheckValidUserName(str: string) {
  var code, i, len;
  
  if (str.length === 0)
    return false

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

type SignupProps = {
  setToken: CallableFunction
}
export default function SignupBox({ setToken }: SignupProps) {

  const [pass, isValid] = useState('')
  const [user_name, userIsValid] = useState('')
  const [registerMsg, setMsg] = useState('')


  const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    //const { token, removeToken, setToken } = useToken();
    event.preventDefault();

    const { email, password, username, dob } = event.target as typeof event.target & {
      email: { value: string }
      password: { value: string }
      username: { value: string }
      dob: { value: string }
    }

    axios({
      method: "POST",
      url: SOURCE_IP + "/sign_up",
      headers: {
        email: email.value,
        password: password.value,
        profile: username.value,
        birth: dob.value
      }
    }).then((response) => {
      setToken(response.data.access_token)
      
      axios({
        method: "POST",
        url: SOURCE_IP + "/submitverification",
        headers: {
          email: email.value,
        }
      }).then((response) => {
        if (response.data.access_token) {
          setToken(response.data.access_token)
        }
        console.log(response)
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
        }
      })
    }).catch((error) => {
      if (error.response) {
        if (error.response.data.msg === "Duplicate email or username!")
          setMsg("Username and/or email already taken.")
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
      }
    })

  }

  return (
    <>
      <div className="signup-box">
        <h2>Sign Up</h2>
        <form onSubmit={handleSignup}>
          <div className="regdiv">
            <label className="regfield">
              Email
              <input className="forminput" type="email" name="email" />
            </label>
          </div>
          <div className="regdiv">
            <label className="regfield">
              Password
              <input className="forminput" type="password" onChange={(e) => { CheckValidPass(e.target.value) ? isValid('Valid password!') : isValid('Password must be 8-50 characters, contain one capital letter, one special character, and one number.') }} name="password" />
            </label>
          </div>

          <div className="regdiv">
            <label className="regfield">
              Username
              <input className="forminput" type="text" onChange={(e) => { CheckValidUserName(e.target.value) ? userIsValid('Valid username!') : userIsValid('Username can only contain alphanumeric characters.') }} name="username" />
            </label>
          </div>
          <div className="regdiv">
            <label className="regfield">
              Birthdate
              <input className="forminput" type="date" name="dob" />
            </label>
          </div>
          <br></br>
          <div className="passvalidation">
            <label>
              {pass}
            </label>
          </div>
          <div className="passvalidation">
            <label>
              {user_name}
            </label>
          </div>
          <div className="endform">
            <input className="subdiv btn-backdrop" type="submit" value="Sign Up" disabled={!(pass === "Valid password!" && user_name === "Valid username!")} /> &nbsp;&nbsp;
            <br></br>
            <div className="logbox">
              Already have an account? <a className="logtext" href="/login">Log in</a> instead!
            </div>
          </div>
          <div className="signerrmsg">
            <label>
              {registerMsg}
            </label>
          </div>
        </form>
        {/**We need a way to set the token inside the COMPONENT
         * Currently it TRIES to set it in the imported ts file.
         * However it does not work and needs to be set inside the 
         * component with the passed function in props to prevent issues.
         * ((setToken is passed from App.tsx))
         */}
      </div>
    </>
  );
}