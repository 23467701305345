import React from "react";
import MessageBox from "../components/Messages/MessageBox"

type MessageProps = {
  setToken: CallableFunction;
  token: string;
}

export default function Messages({setToken, token} : MessageProps) {
  return (
      <MessageBox setToken={setToken} token={token}/>
  )
};