import React from 'react';
import Message from '../Messages/Message';

type ThreadAdminProps = {
    setToken : CallableFunction;
    token : string;
    user : string;
    msgList?: Msg[] | undefined;
}
export default function ThreadAdmin({setToken, token, user, msgList} : ThreadAdminProps) {

    if (user !== "") {
        return (
            <div className="threadfull">
                <div className="thread-msgs">
                    {msgList && 
                    
                        msgList.map((d, index) => (<Message data={d} key={index}></Message>))}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="threadempty">

            </div>
        )
    }
}