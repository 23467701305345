import React from "react";
import axios from "axios";
import './header.css';
import { useNavigate } from "react-router-dom";
import VerifyBanner from "../Common/verify-banner";
import constants from "../../constants";
const { ICON_PATH, SOURCE_IP} = constants();

type HeaderProps = {
    token: string | null
    removeToken: CallableFunction
    admin: boolean
    isVerified: boolean
    userEmail: string;
}
export default function Header({ token, removeToken, admin, isVerified, userEmail }: HeaderProps) {
    const setToken = (newToken: string) => {
        token = newToken;
    }

    const navigate = useNavigate();

    const LogOut = () => {
        console.log("x")
        axios({
            method: "DELETE",
            url: SOURCE_IP + "/log_out",
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((response) => {
            removeToken()
            console.log("LOGGING OUT")
            navigate("/login")
        }).catch((error) => {
            if (error.response) {
                console.log(error.response)
            }
            removeToken()
            console.log("LOGGING OUT")
            navigate("/login")
        })
    }

    if (token != null) {
        return (
            <>
                <div className="header">
                    <div className="logo">
                        <img id="icon" src={ICON_PATH} alt="queueUp Logo" width="80" height="80"/>
                    </div>

                    <div className="nav-wrapper">
                        {admin ? <a href="/admin">Admin</a> : <></>}
                        <a href="/profile">Profile</a>
                        <a href="/matches">Matches</a>
                        <a href="/messages">Messages</a>
                        <div className="headertext" onClick={LogOut}>Logout</div>
                    </div>



                    
                </div>

                {
                !isVerified ? 
                <>
                <VerifyBanner email={userEmail} setToken={setToken} token={token}  /> 
                </>
                : 
                <></>}

            </>
        );
    } else {
        return (
            <>
                <div className="header">
                    <div className="logo">
                        <img id="icon" src={ICON_PATH} alt="queueUp Logo" width="80" height="80"/>
                    </div>
                    <div className="nav-wrapper">
                        <a href="/login">Login</a>
                    </div>

                </div>

            </>
        )
    }
};
