import React from 'react'
import GeneralButton from '../Common/general-button'

export default function AdminMenu() {

  return (
    <>
      <div className='admin-header'>
        <h1>QueueUp Administrator Panel</h1>
      </div>
      <div className='admin-nav-wrapper'>

        <GeneralButton lbl="View Active Reports" dir="/admin/unanswered" />
        <br />
        <GeneralButton lbl="View Suspended Users" dir="/admin/bannedusers" />
      </div>
    </>
  )
}
