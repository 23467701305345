import React, { useState, useEffect} from 'react';
import "./Message"
import axios from "axios";
import ThreadFull from './ThreadFull';
import ThreadPreview from './ThreadPreview';
import constants from '../../constants';
import { PulseLoader } from "react-spinners"

type MessageBoxProps = {
    setToken: CallableFunction;
    token: string;
}

const { bu_def, SOURCE_IP } = constants();

export default function MessageBox({ setToken, token }: MessageBoxProps) {

    //huh?
    const threadlist_def: ThreadList = {
        allthreads: []
    }

    const threads_def: Threads = {

    }

    const [whoToView, setView] = useState('')
    const [msgCount, setMsgCount] = useState(0)
    const [msgList, setMsgList] = useState<Msg[]>([])
    const [sortedThreads, setSortedThreads] = useState<ThreadList>(threadlist_def)
    const [allThreads, setAllThreads] = useState<Threads>(threads_def)
    const [allUsers, setAllUsers] = useState<string[]>([])
    const [isLoading, setLoading] = useState(true)
    //const [thisThread, setThisThread] = useState<SingleThread>(thread_def)
    const [isBlockedList, setIsBlockedList] = useState<boolean[]>([])
    const [curBlocked, setCurBlocked] = useState(false)

    const TIME_MS = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            axios({
                method: "GET",
                url: SOURCE_IP + "/messages",
                headers: {
                    Authorization: `Bearer ${token}`,
                    count: "1"
                }
            }).then((response) => {
                console.log(response);
                if (response.data.access_token) {
                    setToken(response.data.access_token)
                    console.log("TOKEN FOUND")
                }
                setMsgCount(response.data)
            }).catch((error) => {
                if (error.data && error.data.access_token) {
                    setToken(error.data.access_token)
                    console.log("TOKEN FOUND")
                }
            })
        }, TIME_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [setToken, token])

    const doLoading = () => {
        //setIsBlockedList([])

        axios({
            method: "GET",
            url: SOURCE_IP + "/messages",
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            let threads: Threads = response.data.threads
            setAllThreads(response.data.threads)
            setAllUsers(response.data.sorted)
            console.log(response.data)

            //response.data.sorted.map((threadUser: string) => {
            //    thisThread.user = threadUser
            //    thisThread.messages = threads[threadUser]
            //    sortedThreads.allthreads.push(thisThread)
            //    setLoading(false)
            //})
            //                                                              ^
            //Attempt to replace above code block   |
            response.data.sorted.forEach((threadUser: string) => {
                const thisThread : SingleThread = {
                    user : threadUser,
                    messages : threads[threadUser],
                }
                sortedThreads.allthreads.push(thisThread)
                setSortedThreads(sortedThreads)
                setLoading(false)
            })


            setLoading(false)
        }).catch((error) => {
            if (error.data && error.data.access_token) {
                setToken(error.data.access_token)
                console.log("TOKEN FOUND")
            }
        })

    }

    //TODO: Do something else here ?
    useEffect(() => {
        console.log("doing loading")
        doLoading()
 /* eslint-disable */ // Temp for my sanity
    }, [msgCount])

    const threadClick = (user: string, curBlock: boolean) => {
        if (user !== "CLOSE THREAD") {
            console.log("CHANGED THREAD")
            if (allThreads[user][0] === bu_def) {
                setCurBlocked(true)
            } else {
                setCurBlocked(false)
            }

            setView(user)
        }
        else
            setView("") // if you click the already open thread
    }


    //Update the state of the message list to update the messages instantly after send
    const updateMessagesScreen = (data: Msg) => {
        //console.log(data)
        setMsgList([...msgList, data])
        let newMsgList: Msg[] = [data]
        newMsgList.push(...allThreads[whoToView])// append old thread to end of new thread
        //console.log(newMsgList)
        allThreads[whoToView] = newMsgList
        setAllThreads(allThreads)
        //console.log(msgList)
    }

    if (isLoading) {
        //doLoading()
        return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
    }
    else if (allUsers.length > 0) {
        //console.log(allThreads[whoToView])
        return (
            <div className="page messagepage">
            <div className="inbox">
                <div className="thread-preview-set">

                    <div className='tp-header-wrapper'>
                        <div className='tp-header-text'>Messages</div>
                    </div>

                    {allUsers.map((user, index) => {

                        // Create message if empty messages array is received and push to array.
                        if (allThreads[user][0] === undefined) {
                            console.log("blocked here")
                            allThreads[user].push(bu_def)
                            isBlockedList[index] = (true)
                            setIsBlockedList(isBlockedList)
                        }

                        return (
                            <div className="thread-preview-box" onClick={() => threadClick(user, isBlockedList[index])} key={index}>
                                <ThreadPreview
                                    user={user}
                                    messages={allThreads[user]}
                                    selected={whoToView}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="thread-box">
                    <div className="thread-holder">
                        <ThreadFull
                            setToken={setToken}
                            token={token}
                            user={whoToView}
                            doLoading={doLoading}
                            msgList={allThreads[whoToView]}
                            setMessageList={updateMessagesScreen}
                            blocked={curBlocked}
                            setIsBlocked={setCurBlocked}
                        />
                    </div>
                </div>
            </div>
            </div>
        )
    } else if(allUsers.length === 0) {
        return (
            <div className="page messagepage">
            <div className="inbox">
                <div className="thread-preview-set">

                    <div className='tp-header-wrapper'>
                        <div className='tp-header-text'>Messages</div>
                    </div>

                    {allUsers.map((user, index) => {

                        // Create message if empty messages array is received and push to array.
                        if (allThreads[user][0] === undefined) {
                            console.log("blocked here")
                            allThreads[user].push(bu_def)
                            isBlockedList[index] = (true)
                            setIsBlockedList(isBlockedList)
                        }

                        return (
                            <div className="thread-preview-box" onClick={() => threadClick(user, isBlockedList[index])} key={index}>
                                <ThreadPreview
                                    user={user}
                                    messages={allThreads[user]}
                                    selected={whoToView}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="thread-box">

                <div className="thread-holder-err">
                    Your inbox is empty!
                    <div className='thread-holder-err-text'>
                        Go to the matches page and find some new teammates.
                    </div>
                </div>
                </div>
            </div>
            </div>
        )
    }else{
        return (
            <div className="inbox">
                <div className="thread-holder-err">
                    ERROR?
                </div>
            </div>
        )
    }
}