import React, { useState, useEffect } from "react";
import axios from "axios";
import constants from "../../constants";
import "./image-upload-box.css"
import GeneralButton from "../Common/general-button";
const { NO_IMG_PATH, BASE_IMG_PATH, SOURCE_IP } = constants();

type ImageUploaderProps = {
    toggleOpen: CallableFunction;
    setToken: CallableFunction;
    token: string;
    user: UserObject;
}

export default function ImageUploader({ toggleOpen, setToken, token, user }: ImageUploaderProps) {
    const [userImage, setUserImage] = useState<File>()
    const [userMsg, setMsg] = useState('')
    const [preview, setPreview] = useState<string | undefined>()

    useEffect(() => {
        if (!userImage) {
            setPreview(BASE_IMG_PATH + user.username + ".png")
            return
        }

        const objectUrl = URL.createObjectURL(userImage)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [userImage, user.username])

    const handleImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files != null) {
            setUserImage(event.target.files[0]);
        }
    }

    const handleImageSubmit = async () => {
        let formData = new FormData();
        if (userImage !== undefined) {
            formData.append("image", userImage);
            axios({
                method: "POST",
                url: SOURCE_IP + "/image_add",
                data: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": 'multipart/form-data'
                }
            }).then((response) => {
                console.log(response)
                if (response.data.access_token) {
                    setToken(response.data.access_token)
                    console.log("TOKEN FOUND")
                }
                setMsg("Account updated! You may now go back.")
            }).catch((error) => {
                console.log(error)
                if (error.response) {
                    if (error.data && error.data.access_token) {
                        setToken(error.data.access_token)
                        console.log("TOKEN FOUND")
                    }
                    setMsg(error.response.data.msg)
                    console.log(error.response.data)
                    //console.log(error.response)
                    //console.log(error.response.status)
                    //console.log(error.response.headers)
                }
            })
        } else {
            setMsg("No image selected.")
        }
    }

    const replaceImage = (error: any) => {
        //replacement of broken Image
        error.target.src = NO_IMG_PATH
    }

    //Where the statbox is declared, place component that takes the userData.games as a prop and displays unique box for each linked game.
    return (
        <>
            <div id="upload-wrapper">
                <div id="prof-img">
                    <img alt="temp_ Your User Avatar" id="prof-img-edit" src={preview} onError={replaceImage} />
                </div>
                <input type="file" id="img" name="img" accept="image/*" onChange={handleImageSelect}>
                </input>
                <br/>
                <div id="upload-message">{userMsg}</div>
                <div id="style-button-submit"><GeneralButton lbl="Change Picture" diy={() => handleImageSubmit()}></GeneralButton></div>
                <div id="style-button-back"><GeneralButton lbl="Back" diy={toggleOpen}></GeneralButton></div>
            </div>
        </>
    );
}