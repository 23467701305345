import React, { useState, ChangeEvent } from "react";
import MatchBox from "../components/Matches/match-box";
import axios from "axios";
import Defs from "../constants";
import { PulseLoader } from "react-spinners";
import GeneralButton from "../components/Common/general-button";
import PopupBox from "../components/Common/popup-box";
import MatchMessageBox from "../components/Matches/match-msg-box";

//use a useState or useEffect to change the selected game on the screen?
const SearchParams: SearchFilter = {
    game: "lol",
    lang: "",
    role: ""
}
const games = Defs().GamesParam
const langs = Defs().LangParam
const roles = Defs().RoleParam
const SOURCE_IP = Defs().SOURCE_IP

const loadMoreSize = 8

type MatchProps = {
    setToken: CallableFunction;
    token: string;
}
export default function Matches({ setToken, token }: MatchProps) {

    //Control ammount of matches seen on the page at once.
    const [more, setMore] = useState(loadMoreSize);
    const handleMoreMatches = () => {
        setMore(more + loadMoreSize);
    };

    //States for searching/filtering
    const [filters, setFilters] = useState<SearchFilter>(SearchParams)
    const [matchWasEmpty, setMatchEmpty] = useState(false);
    const [filtersVis, setFiltersVis] = useState(true);

    //List of users received from the axios call.
    const [foundUsers, setFoundUsers] = useState<MatchedUserObject[]>()
    const [isLoading, setLoading] = useState(false);

    //Selected user for msg popup
    const [wantsToMsg, setWantsToMsg] = useState<string>("");
    const [toggleMsgBox, setToggleMsgBox] = useState(false);


    function SetMessagePopup(){
        setToggleMsgBox(true)
    }
    function closeMsgPopup(){
        setToggleMsgBox(false)
    }

    //TEMP testing axios headers types
    function setCurGame(gameStr: string) {
        SearchParams.game = gameStr
        setFilters(SearchParams)
    }

    function toggleFiltersVis() {
        setFiltersVis(!filtersVis)
    }

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault()
        console.log(event.target.id)

        if (event.target.id === "search-lang") {
            SearchParams.lang = event.target.value
            setFilters(SearchParams)
            console.log(event)
        }
        else if (event.target.id === "search-role") {
            SearchParams.role = event.target.value
            setFilters(SearchParams)
            console.log(event)
        }

        console.log(SearchParams)
        console.log(filters)
    }

    const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true)
        await getMatches()
        setFilters(SearchParams)
        setLoading(false)

    }

    const getMatches = async () => {
        console.log(filters)

        await axios({
            method: "GET",
            url: SOURCE_IP + "/matches",
            headers: {
                Authorization: `Bearer ${token}`,
                game: filters.game,
                lang: filters.lang,
                role: filters.role
            }
        }).then((response) => {
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(response.data.matches)
            //regUserList = response.data.matches
            setFoundUsers(response.data.matches) // list of users, pre compatability checking (at least right now. Compatability check location is still unknown.)
            filters.lang = "";
            filters.role = "";
            if (response.data.matches.length <= 0) {
                setMatchEmpty(true);
            }else{
                setFiltersVis(false)
            }
            
            setLoading(false);

        }).catch((error) => {
          if (error.data && error.data.access_token) {
            setToken(error.data.access_token)
            console.log("TOKEN FOUND")
          }
          filters.lang = "";
            filters.role = "";
            setLoading(false);
        })
    }

    if (isLoading) {
      return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
    }

    return (
        <>

            {
                toggleMsgBox &&
                <>
                    <PopupBox toggleOpen={setToggleMsgBox} children={<MatchMessageBox setToken={setToken} token={token} whoToMessage={wantsToMsg} closeBox={closeMsgPopup}/>}/>
                </>
            }

            <div className="page matches-page" >

                <div className="matches-flex">
                    <div className="matches-filter-col">

                        {
                            (Object.keys(games) as Array<keyof typeof games>).map((key, index) => {
                                let imgsrc: string = "/imgs/gameicons/" + games[key] + ".png"
                                
                                let selectedTab : string = "game-selector"
                                if(SearchParams.game === games[key]){
                                    selectedTab = "game-selector selected-game"
                                }

                                return (
                                    <div className={selectedTab} onClick={() => { setCurGame(games[key]) }}>
                                        <img alt="temp_ Game Icon" className="game-sel-ico" src={imgsrc}>
                                        </img>
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="matches-content-col">

                        {//set the visibility of the filter box
                            filtersVis &&
                            <>
                                <div className='player-filter'>
                                    <form onSubmit={handleSearch}>
                                        <div className="optBox">
                                            <label>
                                                Looking For:
                                                <br />
                                                <select className="sel-sizing" id="search-role" onChange={handleChange}>
                                                    <option value={""}>Any Role</option>
                                                    {
                                                        (Object.keys(roles) as Array<keyof typeof roles>).map((key, index) => {
                                                            return (
                                                                <option key={key} value={roles[key]}>{key}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </label>
                                            <br />
                                            <label>
                                                Language
                                                <br />
                                                <select className="sel-sizing" id="search-lang" onChange={handleChange}>
                                                    <option value={undefined}>All</option>
                                                    {
                                                        (Object.keys(langs) as Array<keyof typeof langs>).map((key, index) => {
                                                            return (
                                                                <option key={key} value={langs[key]}>{key}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </label>
                                            <br />
                                            <br />
                                            <div>
                                                <input className="submit-match-params btn-backdrop" type="submit" value="Find a Match!" />
                                            </div>
                                            <div>
                                                <label>
                                                </label>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>

                        }

                        {//display the filter button in the corner when box is hidden
                            !filtersVis &&
                            <>
                                <div className="filters-toggle-btn">
                                    <GeneralButton lbl="Filters" diy={toggleFiltersVis}/>
                                </div>
                            </>
                        }


                        {//show a message telling the player that there were no matches
                        matchWasEmpty &&
                            <h2 id="nomatchtext">No matches found! Have you played some ranked games this season?</h2>
                        }


                        {/**Render the list of Matches that has been retrieved from the database here*/}
                        {
                            (
                                foundUsers?.slice(0, more)?.map((matchObj, index) => {
                                    //console.log(index)
                                    return (
                                        <MatchBox userMatch={matchObj} token={token} setToken={setToken} setUserToMessage={setWantsToMsg} openMessagePopup={SetMessagePopup} />
                                    )
                                })
                            )
                        }

                        <div className="more-matches-box">
                        {
                            //If the list of users is undefined then do not attempt to display the show more button
                            (foundUsers !== undefined) &&
                            //If the number of matched users is less than the currently set display count then do not display the button to show more matches
                            (more < foundUsers?.length && (
                                <div style={{width: "50%", margin: "auto"}}>
                                    <GeneralButton lbl="Load More" diy={handleMoreMatches}/>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}