import React from 'react';
import MessageSender from './MessageSender';
import Message from './Message';
import BlockButton from '../Common/block-button';
import ReportButton from '../Common/report-button';
import constants from '../../constants';
const { NO_IMG_PATH, BASE_IMG_PATH } = constants();

type ThreadFullProps = {
    setToken: CallableFunction;
    token: string;
    user: string;
    doLoading: CallableFunction;
    msgList: Msg[];
    setMessageList: CallableFunction;
    blocked: boolean;
    setIsBlocked: CallableFunction;
}
export default function ThreadFull({ setToken, token, user, doLoading, msgList, setMessageList, blocked, setIsBlocked }: ThreadFullProps) {

    const SentMsg = (data: Msg) => {
        setMessageList(data)
    };

    const replaceImage = (error : any) => {
        //replacement of broken Image
        error.target.src = NO_IMG_PATH
    }
    let imgUrl : string = BASE_IMG_PATH + user + ".png"

    if (user !== "") {
        console.log(blocked)

        return (
            <div className="threadfull">
                <div className='thread-wrapper'>
                    <div className="thread-header">
                        <BlockButton
                            uToBlock={user}
                            setToken={setToken}
                            token={token}
                            isBlocked={blocked}
                            setIsBlocked={setIsBlocked}
                            doLoading={doLoading}
                        />
                        <ReportButton uToReport={user} setToken={setToken} token={token} />

                        <div className='thread-username'>
                            {user}
                        </div>
                        <div className='thread-full-icon'>
                        <img alt={'temp_ User Avatar for ' + user} id='thread-full-icon-src' src={imgUrl} onError={replaceImage} />
                        </div>
                    </div>
                    <div className="thread-msgs">

                        {// Display messages as normal if user is not blocked
                            !blocked &&
                            msgList.map((d, index) => (<Message data={d} key={index}></Message>))
                        }

                        {// display the blocked box if blocked is true
                            blocked &&
                            <div className='blocked-user-lbl'>You have blocked this user</div>
                        }
                    </div>

                    {//hide the message sender if the user has blocked the other
                        !blocked &&
                        <div className='message-sender-wrapper'>
                            <MessageSender setToken={setToken} token={token} sendTo={user} sentMsg={SentMsg} doLoading={doLoading} />
                        </div>
                    }

                </div>

            </div >
        )
    }
    else {
        return (
            <div className="threadempty">

            </div>
        )
    }
}