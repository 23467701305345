import React, { useEffect, useState } from 'react'
import axios from "axios"
import "./admin.css"
import BackButton from '../Common/back-button';
import constants from "../../constants"
import PopupBox from '../Common/popup-box';
import ConfirmSelectionBox from '../Common/confirm-selection-box';
const { utcToPrettyDate, SOURCE_IP } = constants()


type BannedUsersProps = {
  token: string;
  setToken: CallableFunction;
}
export default function BannedList({ token, setToken }: BannedUsersProps) {

  const [bannedList, setBannedList] = useState<SuspendObject[]>([]);
  const [showPopup, setShowPopup] = useState(false)
  const [curUser, setCurUser] = useState("")

  function togglePopup(username : string){
    setCurUser(username)
    setShowPopup(!showPopup)
  }

  function callbackPardon(){
    pardonUser(curUser)
  }

  //async call to API for list of banned users. Called on page load AND after unban.
  const getBannedUsers = async () => {
    await axios({
      method: "POST",
      url: SOURCE_IP + "/banned_users",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      //console.log(response.data)
      if (response.data.access_token) {
        setToken(response.data.access_token)
        console.log("TOKEN FOUND")
      }
      console.log(response)
      setBannedList(response.data)

    }).catch((error) => {
      if (error.data && error.data.access_token) {
        setToken(error.data.access_token)
        console.log("TOKEN FOUND")
      }
    })
  }

  //async call to API that suspends the user until UTC 0, effectivley unsuspending them.
  const pardonUser = async (user: string) => {
    await axios({
      method: "POST",
      url: SOURCE_IP + "/set_suspension",
      headers: {
        Authorization: `Bearer ${token}`,
        timestamp: 0,
        profile: user,
      }
    }).then((response) => {
      if (response.data.access_token) {
        setToken(response.data.access_token)
        console.log("TOKEN FOUND")
      }
      //reload list of banned users
      getBannedUsers()
      setCurUser("")
      setShowPopup(false)
    }).catch((error) => {
      if (error.data && error.data.access_token) {
        setToken(error.data.access_token)
        console.log("TOKEN FOUND")
      }
      console.log(error)
    })
  }

  useEffect(() => {
    getBannedUsers()
    console.log(bannedList)
/* eslint-disable */ // Temp for my sanity part 2
  }, [])


  return (
    <>
      <div className='admin-header'>
        <h1>Banned Users</h1>
        <BackButton dir='/admin' />
      </div>

      <table className='suspended-users'>
        <thead className='suspended-users-head'>
          <tr>
            <th>Username</th>
            <th>Banned Until</th>
            <th>Banned By</th>
            <th>...</th>
          </tr>
        </thead>

        {/** Make each table row to a user here*/}


        <tbody>
          {bannedList.map((key, index) => {

            return (
              <tr style={{ backgroundColor: index % 2 === 0 ? "#666666" : "#888888" }}>
                <td>{bannedList[index].name}</td>
                <td>{utcToPrettyDate(bannedList[index].until * 1000)}</td>
                <td>{bannedList[index].banned_by}</td>
                {/**TODO: MAKE THIS BUTTON DISPLAY A CONFIRMATION BOX BEFORE EXECUTING AXIOS CALL */}
                <td><div onClick={() => {
                  togglePopup(bannedList[index].name)
                }}
                  className='view-reports'>
                  Unban
                </div></td>
              </tr>
            )
          })
          }
        </tbody>
      </table>

          {showPopup &&

            <PopupBox toggleOpen={setShowPopup} children={<ConfirmSelectionBox lbl="Are you sure you want to unban this user?" callback={callbackPardon} closeBox={togglePopup}/>}/>
          }

    </>
  )
}
