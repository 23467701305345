import React, { useState } from "react"
import axios from "axios"
import "./game-link-form.css"
import {PulseLoader} from "react-spinners"
import constants from "../../constants"
const {LOL_FLAT_PATH, SOURCE_IP, ls_def} = constants()

type GameLinkFormProps = {
    setToken: CallableFunction;
    token: string;
    user: UserObject;
    options: Game[];
    toggleOpen: CallableFunction;
}

export default function GameLinkForm({ setToken, token, user, options, toggleOpen }: GameLinkFormProps) {
    const [isLoading, setLoading] = useState(false);
    const [gameChoice, setGameChoice] = useState('');
    const [registerMsg, setMsg] = useState('');

    const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
        //const { token, removeToken, setToken } = useToken();
        event.preventDefault();
        setLoading(true);
        let { lol_region, lol_id } = event.target as typeof event.target & {
            lol_region: { value: string }
            lol_id: { value: string }

        }
        if (lol_region === undefined || lol_region === null || lol_id === undefined || lol_id === null) {
            lol_region = { value: "" };
            lol_id = { value: "" };
        }
        let lol_cont = ""
        const americas = ["na1", "la1", "br1", "vn2"]
        const asia = ["ph2", "ru", "kr", "jp1"]
        const sea = ["oc1"]
        const europe = ["eun1", "euw1"]

        if (americas.includes(lol_region.value)) {
            lol_cont = "americas"
        } else if (asia.includes(lol_region.value)) {
            lol_cont = "asia"
        } else if (sea.includes(lol_region.value)) {
            lol_cont = "sea"
        } else if (europe.includes(lol_region.value)) {
            lol_cont = "europe"
        }

        axios({
            method: "POST",
            url: SOURCE_IP + "/link",
            headers: {
                game: "lol",
                region: lol_region.value,
                continent: lol_cont,
                summoner: lol_id.value,
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            user.games.lol = ls_def;
            setLoading(false);
            setMsg("Account Linked!");
        }).catch((error) => {
            if (error.data && error.data.access_token) {
                setToken(error.data.access_token)
                console.log("TOKEN FOUND")
            }
            if (error.response) {
                console.log(error.response.data)
                //console.log(error.response)
                //console.log(error.response.status)
                //console.log(error.response.headers)
            }
            if (error.response.status === 429) {
                setMsg("Server is busy at the moment. Please try again later.")
            } else {
                setMsg("Account unsuccessfully linked. Check your region/name and try again.")
            }
            setLoading(false);
        })
    }
    if (isLoading) {
        return <div id="loader"><PulseLoader color="#FFFFFF" size="20px" margin="5px"></PulseLoader></div>
    }
    else if (gameChoice !== "") {
        return (<form onSubmit={handleSignup}>
            <div className="linkBox">
                <label className="label">
                    <div id="labelHeader">Region</div>
                    <select id="lol_region">
                        <option value="na1">North America</option>
                        <option value="la1">Latin America</option>
                        <option value="br1">Brazil</option>
                        <option value="vn2">Venezuela</option>
                        <option value="eun1">Europe (North)</option>
                        <option value="euw1">Europe (West)</option>
                        <option value="ph2">Philippines</option>
                        <option value="ru">Russia</option>
                        <option value="kr">South Korea</option>
                        <option value="jp1">Japan</option>
                        <option value="oc1">Oceania</option>
                    </select>
                </label>
                <label className="label">
                    <div id="labelHeader">Summoner ID</div>
                    <input type="text" name="lol_id" maxLength={255} />
                </label>
                <div>
                    <input type="submit" value="Update!" />
                </div>
                <div>
                    <label>
                        {registerMsg}
                    </label>
                </div>
                <button onClick={() => toggleOpen()}>
                    Back
                </button>
            </div>
        </form>)
    } else {
        // in the full implementation, the return() below would be a mapping of icon references to their corresponding game value
        return (
            <div className="linkBox">
                <form onSubmit={handleSignup}>
                    <div>
                        <div id="league-link-img">
                            <img alt="temp_ League of Legends" width="80px" src={LOL_FLAT_PATH} onClick={() => setGameChoice("league")}></img>
                        </div>
                    </div></form>
                <button onClick={() => toggleOpen()}>
                    Back
                </button>
            </div>)
    }
}