import React, { useEffect, useState } from 'react'
import axios from "axios"
import "./admin.css"
import ThreadAdmin from './thread-common';
import GeneralButton from '../Common/general-button';
import constants from "../../constants"
const {SOURCE_IP} = constants()

type ReportMsgBoxProps = {
    token: string;
    setToken: CallableFunction;
    closeBox: CallableFunction;
    user: string;
    reportee: string;
}
export default function ReportMsgBox({ token, setToken, closeBox, user, reportee }: ReportMsgBoxProps) {

    const threads_def: Threads = {}

    const [isLoading, setIsLoading] = useState(true)
    const [allThreads, setAllThreads] = useState<Threads>(threads_def)

    useEffect(() => {
        console.log("REPORTEE: " + reportee)
        const getBannedUsers = async () => {
            await axios({
                method: "GET",
                url: SOURCE_IP + "/reported_messages",
                headers: {
                    Authorization: `Bearer ${token}`,
                    profile: user,
                }
            }).then((response) => {
                //console.log(response.data)
                if (response.data.access_token) {
                    setToken(response.data.access_token)
                    console.log("TOKEN FOUND")
                }
                console.log(response)
                console.log(response.data.threads)
                console.log(response.data.threads[reportee])
                setAllThreads(response.data.threads)
                setIsLoading(false)

            }).catch((error) => {
                if (error.data && error.data.access_token) {
                    setToken(error.data.access_token)
                    console.log("TOKEN FOUND")
                }
                console.log(error)
            })
        }
        getBannedUsers()
    }, [reportee, user, token, setToken])

    if (isLoading) {
        return (
            <>
                ...
            </>
        )
    }
    else {
        return (
            <>
                <div className='reported-messages-box'>
                    <ThreadAdmin token={token} setToken={setToken} user={user} msgList={allThreads[reportee]} />

                    <div className='thread-labels'>
                        <div className='thread-label'>
                            {reportee}
                        </div>

                        <GeneralButton lbl="Close" diy={closeBox} />

                        <div className='thread-label'>
                            {user}
                        </div>

                    </div>

                </div>
            </>
        )
    }
}
