import React from 'react'
import GeneralButton from './general-button';

type ConfirmSelectionBoxProps = {
    lbl: string;
    btnlbl?: string;
    callback : CallableFunction;
    closeBox : CallableFunction;
}
export default function ConfirmSelectionBox({lbl, btnlbl, callback, closeBox}: ConfirmSelectionBoxProps) {
  return (
    <>
            <div className='confirm-popup-box'>
                <h2>{lbl}</h2>
                <div className='confirm-popup-buttons'>
                    <GeneralButton lbl="OK" diy={callback}/>
                    <GeneralButton lbl="Nevermind" diy={closeBox} colorScheme={1}/>
                </div>
            </div>
        </>
  )
}
