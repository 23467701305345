import React, {useState} from 'react';
import axios from "axios";
import {useLocation} from 'react-router-dom';
import constants from "../constants"
const {SOURCE_IP} = constants()

type ResetProps = {
    setToken: CallableFunction;
}

function CheckValidPass(str: string) {

    const passw = /^(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$%^&])[a-zA-Z\d!@#$%^&]{8,50}$/;
    if (!str.match(passw))
      return false;
  
    return true;
}

export default function Reset({setToken} : ResetProps) {
    const location = useLocation();
    const [resetMsg, setMsg] = useState('');
    const generatedLink = location.pathname.split("/").pop();
    const [pass, isValid] = useState('')

    const resetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const {newpass} = event.target as typeof event.target & {
            newpass: { value: string }
          }
        console.log(newpass);
        console.log(generatedLink)
        axios({
            method: "POST",
            url: SOURCE_IP + "/passreset",
            headers: {
              password: `${newpass.value}`,
              userlink: `${generatedLink}`
            }
          }).then((response) => {
            if (response.status === 201) {
                setMsg("Your password was successfully reset!")
            } else {
                setMsg("There was an error resetting your password. Please make sure the link you've entered is correct and has not expired.")
            }
          }).catch((error) => {
            if (error.response) {
              setMsg("There was an error resetting your password. Please make sure the link you've entered is correct and has not expired.")
              console.log(error.response.data)
              //console.log(error.response)
              //console.log(error.response.status)
              //console.log(error.response.headers)
            }
          })
    }

    return (
      <div className='page'>
        <div className='title'>
          <h1>Reset Your Password</h1>
          <form onSubmit={resetPassword}>
          <div className="regdiv">
            <label className="regfield">
              Password
              <input className="forminput" type="password" onChange={(e) => { CheckValidPass(e.target.value) ? isValid('Valid password!') : isValid('Password must be 8-50 characters, contain one capital letter, one special character, and one number.') }} name="newpass" />
            </label>
          </div>
          <br></br>
          <div className="passvalidation">
            <label>
              {pass}
            </label>
          </div>
          <div className="endform">
            <input className="subdiv" type="submit" value="Reset password" disabled={!(pass === "Valid password!")} /> &nbsp;&nbsp;
          </div>
        </form>
          <h2>{resetMsg}</h2>
        </div>
      </div>
    )
};