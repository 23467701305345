import React from 'react';
import constants from '../../constants';
const {NO_IMG_PATH, BASE_IMG_PATH} = constants()

type ThreadPreviewProps = {
    user : string;
    messages : Msg[];
    selected : string;
}
export default function ThreadPreview({user, messages, selected}: ThreadPreviewProps) {
    const replaceImage = (error : any) => {
        //replacement of broken Image
        error.target.src = NO_IMG_PATH
    }
    let imgUrl : string = BASE_IMG_PATH + user + ".png"
    
    let previewMsg = messages[0].message;
    const MAX_CHAR = 24
    if (previewMsg.length > MAX_CHAR) {
        console.log("X");
        previewMsg = previewMsg.substring(0, MAX_CHAR-3) + "..."
    }
    if (selected !== user) {
        return (
            <div className="threadpreview">
                <div className="thread-preview-icon">
                    <img alt='temp_ User Avatar' src={imgUrl}width="50px" onError={replaceImage}></img>
                </div>
                <div className="thread-preview-side">
                    <div className="thread-preview-side-header">
                        {user}
                    </div>
                    <div className="thread-preview-side-text">
                        {previewMsg}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="threadpreview-selected">
                <div className="thread-preview-icon">
                    <img alt='temp_ User Avatar' id="thread-preview-icon-src" src={imgUrl} width="50px" onError={replaceImage}></img>
                </div>
                <div className="thread-preview-side">
                    <div className="thread-preview-side-header">
                        {user}
                    </div>
                    <div className="thread-preview-side-text">
                        {previewMsg}
                    </div>
                </div>
            </div>
        )
    }
}