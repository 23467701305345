import React from 'react'
import './general-button.css'
import { useNavigate } from 'react-router-dom';

type GeneralButtonProps = {
    lbl : string;
    dir?: string;
    colorScheme?: number;
    diy?: CallableFunction;
}
export default function GeneralButton({ lbl, dir, colorScheme, diy}: GeneralButtonProps) {
    const navigate = useNavigate()

    let classString = "general-button btn-backdrop"

    if(!colorScheme || colorScheme === 0){
        classString = classString + " gbg1"
    }else if(colorScheme === 1){
        classString = classString + " gbg2"
    }

    if(diy){
        return (
            <div className={classString} style={{}} onClick={() => {diy()}}>{lbl}</div>
        )
    }else if(dir){
        return (
            <div className='general-button btn-backdrop' onClick={() => { navigate(dir) }}>{lbl}</div>
        )
    }else{
        return (
            <div className='general-button btn-backdrop' onClick={() => { }}>{lbl}</div>
        )
    }

}
