import React, { useEffect, useState } from 'react';
import axios from "axios";
import './block-report.css'
import constants from "../../constants"
const {SOURCE_IP} = constants()

type BlockButtonProps = {
    uToBlock: string;
    setToken: CallableFunction;
    token: string;
    isBlocked : boolean;
    setIsBlocked : CallableFunction;
    doLoading : CallableFunction;
}
export default function BlockButton({ uToBlock, setToken, token, isBlocked, setIsBlocked, doLoading }: BlockButtonProps) {

    const [isBlockedLbl, setIsBlockedLbl] = useState("...");

    
    useEffect(() => {
        if(isBlocked){
            setIsBlockedLbl("Unblock User")
        }
        else{
            setIsBlockedLbl("Block User")
        }
    }, [isBlocked]);

    const Pressed = () => {
        if(isBlocked){
            UnblockUser()
        }else{
            BlockUser()
        }
    }

    const BlockUser = async() => {
        await axios({
            method: "POST",
            url: SOURCE_IP + "/block",
            headers: {
                Authorization: `Bearer ${token}`,
                blocked: uToBlock
            }
        }).then((response) => {
            //console.log(response.data)
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            setIsBlocked(true)
            setIsBlockedLbl("Blocked")
            doLoading()
        }).catch((error) => {
            if (error.data && error.data.access_token) {
                setToken(error.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(error)
            setIsBlockedLbl("ERROR")
        })
    }

    const UnblockUser = async() => {
        await axios({
            method: "POST",
            url: SOURCE_IP + "/unblock",
            headers: {
                Authorization: `Bearer ${token}`,
                unblocked: uToBlock
            }
        }).then((response) => {
            //console.log(response.data)
            if (response.data.access_token) {
                setToken(response.data.access_token)
                console.log("TOKEN FOUND")
            }
            isBlocked = false
            setIsBlocked()
            console.log(isBlocked)
            setIsBlockedLbl("Unblocked")
            doLoading()
        }).catch((error) => {
            if (error.data && error.data.access_token) {
                setToken(error.data.access_token)
                console.log("TOKEN FOUND")
            }
            console.log(error)
            setIsBlockedLbl("ERROR")
        })
    }


    return (
        <div className='block-button' onClick={() => { Pressed() }}>{isBlockedLbl}</div>
    )
}
